export default {
    faq: {
        title: '幫助中心',
        description: '在 NobleWills 的全面常見問題頁面上，找到有關遺囑撰寫，繼承和資產規劃的問題的明確答案。 立即獲得專家指導，為您帶來平靜。',
        keywords: '遺囑撰寫常見問題,資產規劃查詢,繼承指導,法律遺囑資訊,遺囑認證問題,遺囑解釋,執行人責任,資產分配查詢,監護權查詢,資產規劃建議',
        howCanWeHelp: '我們如何幫助您？',
        commonTopics: '常見主題',
        searchResults: '搜索結果',
        searchFaqs: '搜索常見問題',
        relatedArticles: '相關文章',
    },
};
