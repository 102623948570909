import React, { useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/zh-hk';
import { useTranslation } from 'react-i18next';
import localization, { LanguageEnum, LocaleEnum } from './localization.service';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

export interface IPageContext {
    lang?: string;
    originalPath?: string;
    localizedPath?: string;
    isOnlyVersion?: boolean;
    languageCode?: LanguageEnum;
}

interface IPageContextProvider {
    value: IPageContext;
    children: any;
}

const PageContext = React.createContext<IPageContext>({});

export const PageContextProvider = ({ value, children }: IPageContextProvider) => {
    const { i18n } = useTranslation();
    const location = useLocation();

    const localeCookie = localization.getLocaleCookie();
    if (value.isOnlyVersion && localeCookie) {
        value.lang = localeCookie;
    }

    let momentLocale: string = 'en';
    console.log(value.lang);
    switch (value.lang) {
        case LocaleEnum.enSG:
        case LocaleEnum.enMY:
        case LocaleEnum.enUS:
            i18n.changeLanguage(LanguageEnum.en);
            break;
        case LocaleEnum.zhHK:
            i18n.changeLanguage(LanguageEnum.tc);
            momentLocale = 'zh-hk';
            break;
        case LocaleEnum.enHK:
        default:
            value.lang = LocaleEnum.enHK;
            i18n.changeLanguage(LanguageEnum.en);
            break;
    }

    moment.locale(momentLocale);

    useEffect(() => {
        if (!!localeCookie && localeCookie !== value.lang && !value.isOnlyVersion) {
            const isDefault = localization.getLocaleDetails(localeCookie || '').isDefault;
            navigate(isDefault ? `${value.originalPath}${location.search}` ?? '' : `/${localeCookie}${value.originalPath}${location.search}`);
        }
    }, [localeCookie]);

    return <PageContext.Provider value={value}>{children}</PageContext.Provider>;
};

export const usePageContext = () => React.useContext<IPageContext>(PageContext);
