export default {
    guard: {
        titleHK: 'Hong Kong Temporary Guardians',
        titleSG: 'Singapore Temporary Guardians',
        titleMY: 'Malaysia Temporary Guardians',
        descriptionHK: 'Appoint people you trust to look after your children when you are unreachable for HK${{price}} with a Hong Kong Deed of Temporary Guardianship document.',
        descriptionSG: 'Appoint people you trust to look after your children when you are unreachable for S${{price}} with a Singapore Deed of Temporary Guardianship document.',
        descriptionMY: 'Appoint people you trust to look after your children when you are unreachable for RM{{price}} with a Malaysia Deed of Temporary Guardianship document.',
        keywordsHK: 'Hong Kong temporary guardians, deed of temporary guardianship, child guardians, guardianship',
        keywordsSG: 'Singapore temporary guardians, deed of temporary guardianship, child guardians, guardianship',
        keywordsMY: 'Malaysia temporary guardians, deed of temporary guardianship, child guardians, guardianship',
        heading: 'Temporary',
        heading2: 'Guardians',
        heading3: '',
        subHeading: 'Ensure that your children are looked after and protected by people you trust.',
        productSub: 'Only one needed per family',
        includes1: 'Choose up to 2 couples as temporary guardians',
        includes2: 'PDF version of the documents',
        includes3: 'Guidance on how to sign and witness the documents',
        descriptionHeading: 'How does it work?',
        description:
            'A deed of appointment of temporary guardianship ("DTG") is a document that ensures that your minor children are protected and taken care of by those you trust during unforeseen circumstances. Appoint specific family members or friends that you trust to act as temporary guardians in your absence.',
        descriptionList: 'This document will be actioned if:',
        description2: 'you are abruptly hospitalized, quarantined, missing, stuck abroad, or unreachable by any other means;',
        description3:
            'you unfortunately pass away, at which point the temporary guardians will take care of your children until the permanent guardians set out in your Will can assume the responsibility.',
    },
};
