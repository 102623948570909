import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, GatsbyImageProps } from 'gatsby-plugin-image';
import React from 'react';

export interface IImageEdge {
    node: {
        relativePath: string;
        childImageSharp: {
            fluid: any;
        };
        name: string;
        publicURL: string;
    };
}

export interface ImageProps extends Omit<GatsbyImageProps, 'image'> {
    src: string;
    directory: 'icons' | 'images';
    alt: string;
    objectFit?: GatsbyImageProps['objectFit'];
}

const Image: React.FC<ImageProps> = ({ src, alt, directory, objectFit, ...props }) => {
    const data: any = useStaticQuery(graphql`
        query {
            images: allFile {
                edges {
                    node {
                        relativePath
                        name
                        publicURL
                        childImageSharp {
                            gatsbyImageData(layout: FULL_WIDTH)
                        }
                    }
                }
            }
        }
    `);

    const image = data.images.edges.find((edge: IImageEdge) => {
        return edge.node.relativePath === `${directory}/${src}`;
    });

    if (!image && directory === 'images') {
        const image = data.images.edges.find((edge: IImageEdge) => {
            return edge.node.relativePath === `images/missing-image.png`;
        });
        return <GatsbyImage {...props} image={image.node.childImageSharp.gatsbyImageData} alt={alt} style={{ width: '100%', height: '100%' }} />;
    }

    if (!image) {
        return <span>{alt}</span>;
    }

    if (src.split('.')[1] === 'svg') {
        return <img src={image.node.publicURL} alt={alt} style={{ width: '100%', height: '100%' }} />;
    } else {
        return <GatsbyImage {...props} image={image.node.childImageSharp.gatsbyImageData} alt={alt} style={{ width: '100%', height: '100%' }} objectFit={objectFit} />;
    }
};

export default Image;
