export default {
    pricing: {
        whatIsThe: 'What is the',
        pricing: ' pricing',
        forAWill: 'for a Will?',
        pricingStarts: 'Pricing starts from ',
        andIncludes: ' with unlimited updates from {{membership}}.',
        forCouples: ' for couples',
        start: 'Start for free',
        more: 'More about online Wills',
        keep: 'Keep your',
        upToDate: ' Will up to date',
        membership1:
            'Circumstances in life can change often, and your Will should change with them. That\'s why we offer unlimited updates with our "Editing Membership" for {{price}} per year. Compare that with the significantly higher amendment fees at traditional law firms and Will writing firms. We even throw in the first year for free!',
        membership2: 'Just sign back in anytime and make the necessary amendments. Receive an updated version of your estate planning bundle.',
        faq: 'Frequent questions about pricing',
        package: 'Online estate planning package',
        onlineWills: 'Online Wills',
        basicOnlineWills: 'Basic Online Will',
        basicOnlineWillsDescription: 'Write your Will online in just 15 minutes with free guidance when you need it.',
        advancedOnlineWills: 'Advanced Online Will',
        advancedOnlineWillsDescription: 'Write your Will online in just 15 minutes with a professional review of your estate with one of our specialists.',
        temporaryGuardianshipDescription: 'Appoint temporary guardians for your children.',
        whatsIncluded: "What's Included",
        availableAddons: 'Available Add-ons',
        single: 'Single Will',
        couple: 'Couple Will',
        willIncludes1: 'Last Will and Testament',
        willIncludes2: 'Letter to your Executors (To let them know that you have chosen them)',
        willIncludes3: 'Letter of Wishes',
        willIncludes4: 'List of Assets and Digital Assets',
        willIncludes5: 'PDF versions of all the documents',
        willIncludes6: 'Unlimited updates on the online platform for 30 days',
        willIncludes7: '1 professionally print delivered to you',
        willIncludes8: 'Professional review of your Will and list of assets',
        willIncludes9: 'Video call to confirm identity and to answer any concerns or questions.',
        willIncludes10: 'Appointment of permanent guardians',
        willIncludes11: 'Instructions on how to sign with witnesses.',
        editingMembership: 'Editing Membership',
        membership: 'Membership',
        membershipIncludes1: 'Make unlimited changes to your Will',
        membershipIncludes2: 'Reviewing by our Will specialists with every updated submission',
        membershipIncludes3: 'Continued support',
        membershipIncludes4: 'Notifications when relevant laws change that might affect your Will',
        witnessing: 'Signing and witnessing service',
        print: 'Professional print',
        storageService: 'Storage service',
        countrySpecificWills: 'Country-specific Wills',
        discount: 'discount',
        fixedDiscount: '{{currency}}{{discount}} off',
        percentDiscount: '{{discount}}% off',
        autoApply: 'Automatically applied at checkout.',
    },
};
