export default {
    faq: {
        title: 'Help Center',
        description:
            "Find clear answers to your questions on Will writing, inheritance, and estate planning at NobleWills {{country}}'s comprehensive FAQ page. Get expert guidance for your peace of mind today.",
        keywords:
            '{{country}} Will writing FAQs, Estate planning queries, Inheritance guidance, Legal Will information, Probate questions, {{country}} Wills explained, Executor responsibilities, Asset distribution queries, Guardianship inquiries, Estate planning advice',
        howCanWeHelp: 'How can we help?',
        commonTopics: 'Common topics',
        searchResults: 'Search results',
        searchFaqs: 'Search FAQs',
        relatedArticles: 'Related articles',
    },
};
