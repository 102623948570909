import { navigate, Script } from 'gatsby';
import React, { createContext, ReactNode, useEffect, useState } from 'react';
import CookiesBanner from '../components/banners/cookies-banner';
import IntercomService from '../services/intercom/intercom.service';
import { IIntercomService } from '../services/intercom/types';
import localization from '../services/localization/localization.service';
import { usePageContext } from '../services/localization/page-context';
import EnvironmentUtils from '../utils/environment.utils';

export type MessengerProps = {};

export const MessengerContext = createContext<MessengerProps>({} as MessengerProps);

export type UseIntercomReturn = Omit<IIntercomService, 'boot'> & {};

interface MessengerProviderProps {
    children: ReactNode;
}
const INTERCOM_LOADED = 'intercomLoaded';
const MessengerProvider: React.FC<MessengerProviderProps> = ({ children }) => {
    const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
    const [bannerIsHidden, setBannerIsHidden] = useState<boolean>(false);

    useEffect(() => {
        if (EnvironmentUtils.isProd) {
            const setScriptState = () => {
                setScriptLoaded(true);
            };

            window.addEventListener(INTERCOM_LOADED, setScriptState);

            return () => window.removeEventListener(INTERCOM_LOADED, setScriptState);
        }
    }, []);

    useEffect(() => {
        if (scriptLoaded && bannerIsHidden) {
            new IntercomService().boot();
        }
    }, [scriptLoaded, bannerIsHidden]);

    return (
        <>
            {children}
            <CookiesBanner onHide={() => setBannerIsHidden(true)} />
            {EnvironmentUtils.isProd && bannerIsHidden && (
                <Script id="intercom">{`
                    var APP_ID = "${EnvironmentUtils.GATSBY_INTERCOM_APP_ID}";
                    
                    window.intercomSettings = {
                        app_id: APP_ID
                        };
                    (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + APP_ID;var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);s.onload=function(){window.dispatchEvent(new Event("${INTERCOM_LOADED}"))};};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
                `}</Script>
            )}
        </>
    );
};

export default MessengerProvider;

export function useMessenger(): UseIntercomReturn {
    const intercom = new IntercomService();
    const { lang } = usePageContext();

    const navigateOnFailure = (action: Function) => {
        if (window.Intercom) {
            action();
        } else {
            navigate(localization.localizeLink('/contact/', lang));
        }
    };

    return {
        createUser: intercom.createUser,
        trackEvent: intercom.trackEvent,
        show: () => navigateOnFailure(intercom.show),
        showNewMessage: (content?: string) => navigateOnFailure(() => intercom.showNewMessage(content)),
    };
}
