export default {
    contact: {
        titleHK: 'Hong Kong Will Experts | Contact Us',
        titleSG: 'Singapore Will Experts | Contact Us',
        titleMY: 'Malaysian Will Experts | Contact Us',
        titleUS: 'American Will Experts | Contact Us',
        descriptionHK:
            'Our team of Will writing experts, qualified lawyers, and STEP members are here to answer all of your questions related to estate planning and Will writing.',
        descriptionSG:
            'Our team of Will writing experts, qualified lawyers, and STEP members are here to answer all of your questions related to estate planning and Will writing.',
        descriptionMY:
            'Our team of Will writing experts, qualified lawyers, and STEP members are here to answer all of your questions related to estate planning and Will writing.',
        descriptionUS:
            'Our team of Will writing experts, qualified lawyers, and STEP members are here to answer all of your questions related to estate planning and Will writing.',
        keywordsHK: 'Will Writer Contact, Hong Kong Estate Planning Online, chat, phone, email',
        keywordsSG: 'Will Writer Contact, Singapore Estate Planning Online, chat, phone, email',
        keywordsMY: 'Will Writer Contact, Malaysian Estate Planning Online, chat, phone, email',
        keywordsUS: 'Will Writer Contact, American Estate Planning Online, chat, phone, email',
        guide: 'Let our Will specialists guide you through the inevitable.',
        emailUs: 'Email us',
        email: 'Email',
        emailDescription: 'Prefer using email? Click here to get in touch.',
        chat: 'Chat',
        chatWith: 'Chat with us',
        chatNow: 'Chat now? Click here to instantly speak with our specialists.',
        firstName: 'First Name',
        lastName: 'Last Name',
        messageSent: '',
        getBack: '',
        phone: 'Phone',
        phoneNumber: 'Phone Number',
        phoneCall: 'Call us',
        phoneDescription: 'Already on your phone? Why not just give us a quick call.',
        name: 'Name',
        message: 'Message',
        messagePlaceholder: 'Hello, I am interested in writing a Will.',
        callNow: 'Call Now',
        country: 'Country',
        countryOf: 'Country of Residence',
        countrySelect: 'Select country',
        stay: 'Stay up to date',
        stayBody:
            'We would periodically send out newsletters to notify you of new products, service updates, partnerships, and more. You can unsubscribe at anytime if you wish no to longer receive anything from us.',
        join: 'Join our newsletter',
        whatsapp: 'WhatsApp',
        whatsappUs: 'WhatsApp us',
        whatsappDescription: 'Already on your phone? Why not just WhatsApp us.',
        mailList: 'Please add me to your mailing list!',
        receiveNewsletter: 'I would like to receive email newsletters.',
        welcome: 'Welcome!',
        thankYou: 'Thank you!',
    },
};
