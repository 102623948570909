class EnvironmentUtils {
    static readonly NODE_ENV = process.env.NODE_ENV as string;
    static readonly GATSBY_APP_URL = process.env.GATSBY_APP_URL as string;
    static readonly GATSBY_API_URL = process.env.GATSBY_API_URL as string;
    static readonly GATSBY_INTERCOM_APP_ID = process.env.GATSBY_INTERCOM_APP_ID as string;

    static get isDev(): boolean {
        return this.NODE_ENV === 'development';
    }

    static get isProd(): boolean {
        return this.NODE_ENV === 'production';
    }
}

export default EnvironmentUtils;
