import EnvironmentUtils from '../../utils/environment.utils';
import { IIntercomService } from './types';

class IntercomService implements IIntercomService {
    boot(): void {
        if (window?.Intercom) {
            window.Intercom('boot', {
                app_id: EnvironmentUtils.GATSBY_INTERCOM_APP_ID,
            });
        }
    }

    show(): void {
        if (window?.Intercom) {
            window.Intercom('show');
        }
    }

    /**
     * Creates new user for visitor if one does not exist.
     */
    createUser(): void {
        if (window?.Intercom) {
            window.Intercom('update');
        }
    }

    showNewMessage(message?: string): void {
        if (window?.Intercom && message) {
            window.Intercom('showNewMessage', message);
        }
    }

    trackEvent(title: string, metadata?: Record<string, string | number>): void {
        if (window?.Intercom) {
            window.Intercom('trackEvent', title, metadata);
        }
    }
}

export default IntercomService;
