import UniversalCookies, { Cookie, CookieGetOptions, CookieSetOptions } from 'universal-cookie';

export class CookiesService extends UniversalCookies {
    get<T>(name: string, options?: CookieGetOptions): T {
        return super.get<T>(name, options);
    }

    set(name: string, value: Cookie, options?: CookieSetOptions): void {
        return super.set(name, value, {
            path: '/',
            domain: process.env.NODE_ENV === 'production' ? '.noblewills.com' : 'localhost',
            ...options,
        });
    }

    remove(name: string, options?: CookieSetOptions): void {
        return super.remove(name, options);
    }
}
