import { Box, BoxProps } from '@chakra-ui/layout';
import { forwardRef } from '@chakra-ui/react';
import React from 'react';

export interface ShadowBoxProps extends BoxProps {
    animateOnHover?: boolean;
}

const ShadowBox = forwardRef<ShadowBoxProps, 'div'>(({ animateOnHover = false, children, ...props }, ref) => {
    return (
        <Box
            border="solid 2px"
            borderColor="willBlack.500"
            borderRadius="9px"
            boxShadow="6px 6px 0 0 #1a202c"
            overflow="hidden"
            transition="all 200ms ease-in"
            _hover={animateOnHover ? { transform: 'translateY(-5px)' } : {}}
            ref={ref}
            {...props}
        >
            {children}
        </Box>
    );
});

export default ShadowBox;
