export default {
    epa: {
        title: '持久授權書',
        description: '當您不幸喪失精神行為能力時，為您的家人獲得財政上的保障。',
        keywords: '香港持久授權書, 持久授權書',
        heading: '持久授權書',
        heading2: '',
        heading3: '',
        heading4: '',
        subHeading: '在您喪失精神行為能力時，您的家人能在財政上得到保障。',
        productSub: '包括醫生和律師費用',
        includes1: '首次視頻通話以取得指示',
        includes2: '持久授權書的PDF英文版本',
        includes3: '專業打印文件',
        includes4: '與醫生和律師見面，簽署和見證文件',
        descriptionHeading: '持久授權書有什麼作用',
        descriptionBody: '通過持久授權書，您可以選擇一至兩位人士，在你喪失精神行為能力時為您管理財產。',
        descriptionBody2:
            '如果您不幸突然喪失精神行為能力，在沒有持久授權書情況下，您的個人財產將無法被動用，可能會為您和家人帶來諸多不便。持有持久授權書能讓您的受權人行使您的財產，以便用於交租，償還債務，交付醫療費用和日常開支等用途。',
        descriptionBody3: '查看以下常見問題或幫助頁面，以了解更多有關持久授權書的資訊。',
    },
};
