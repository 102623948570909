export default {
    home: {
        titleHK: '香港網上平安紙訂立',
        descriptionHK: 'NobleWills 是一個容易使用，價格合理，且經由律師和 STEP 成員仔細撰寫平安紙的網上平台。',
        keywordsHK: ', 訂立平安紙香港, 平安紙收費, 網上立平安紙, 預設醫療指示',
        inc1: '為您的子女設立監護人',
        inc1Body: '確保子女受到保障',
        inc2: '保護您的伴侶',
        inc2Body: '確保伴侶生活無憂',
        inc3: '符合{{country}}法律',
        inc3Body: '按照先例並定期更新平安紙',
        inc4: '數據保護',
        inc4Body: '確保資料安全，絕不外洩',
        inc5: '由專員親身支援',
        inc5Body: '與遺囑專家直接對話',
        inc6: '遺產捐贈',
        inc6Body: '以平安紙回饋給慈善機構',
        inc7: '保護您的寵物',
        inc7Body: '確保所有家庭成員都得到照顧',
        inc8: '資產清單',
        inc8Body: '整理及協助您的遺產執行人處理您的資產',
        inc9: '遺贈',
        inc9Body: '向摯親贈送物品、收藏品或現金',
        inc10: '無限更新',
        // "inc10Body": "Keep your Will up to date with our editing membership",
        inc10Body: '隨時隨地登入以更新您的平安紙',
        s: '的',
        online: '網上',
        expert: '專家',
        will: '平安紙',
        writers: '訂立平台',
        headingSubtitle: '提早規劃資產，保障家人未來，',
        headingSubtitle2: '網上平安紙服務由',
        headingSubtitle3: '起，',
        headingSubtitle4: '所有個案，均專人辦理。',
        notJustTemplate: '我們不是一個模版',
        notJustTemplateBody:
            'NobleWills 是一個方便實用的網上訂立平安紙平台，致力於以合理的價格提供最全面、最無縫的訂立平安紙服務。 與我們一起訂立平安紙的每一步，我們都會提供大量指導和支援。',
        onlineService: '網上平安紙服務',
        onlineServiceBody: 'NobleWills 的服務讓平安紙訂立及更新變得更容易。只需回答一些關於您的問題，最快15分鐘內為您度身訂造您的遺產計劃方案。',
        premiumServiceBody: '即使在世界各地坐擁無數資產，我們的遺囑專家均為您一一整理，滿足您特定的意願，節省稅務，免除家人煩惱。.',
        onlineServiceHow: '查看服務流程',
        onlineServicePrice: '查看價格',
        attributes: '為什麼選擇 NobleWills？',
        attribute2: '',
        personal: '線上服務',
        personalBody: '只需按一下線上服務，我們的遺囑專家便會親自處理您的問題和疑慮。',
        personalBody2: '對程序有疑問，想問有關任命監護人的問題，或者只想提供意見？只需按右下角的綠色線上服務按鈕，即可與我們對話。',
        personalBody3: '我們的營業時間是星期一至星期五',
        chat: ' 馬上開始對話',
        guidesBody: '以下包括有關遺產計劃的文章，協助您使用此平台以及理解相關產品內容。',
        moreGuides: '更多指南',
        charitiesBody: '除了在我們的網上平台訂立平安紙外，亦可同時考慮將部分遺產或以現金捐贈給慈善機構。 不妨考慮捐贈以下慈善機構。',
        aWill: '我們的平安紙包括',
        Pricing: '價格一目了然',
        atAGlance: '',
        trusted: '值得信賴',
        trustedBody: '我們確保您的資料安全無虞而平安紙是由律師和國際信託及資產規劃學會成員按照先例仔細撰寫。',
        fast: '快速',
        fastBody: '只需15分鐘完成所有問題，最快在2個工作天內收到平安紙。',
        affordable: '價格相宜',
        // "affordableBody": "Flat fees starting from {{single}} for a single Will. {{couple}} for couple Wills. Unlimited edits for {{membership}} per year.",
        affordableBody: '單一份平安紙低至{{single}}；夫妻各一份平安紙低至{{couple}}。 無限次修改低至{{membership}}（劃一收費）。',

        partner: '邀請您的伴侶',
        partnerBody: '與您的伴侶一起訂立平安紙，為您節省時間和金錢！',
        partnerBody2: '程序如何？',
        partnerBody3: '訂立自己的網上平安紙。',
        partnerBody4: '邀請及選擇分享您的資料給伴侶。',
        partnerBody5: '同時付款可享額外優惠。',
        getStarted: '開始',
        reviews: '客戶評論',
        inTheNews: '傳媒報道',
    },
};
