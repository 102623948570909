import common from './common';
import siteMetadata from './site-metadata';
import home from './home';
import about from './about';
import blog from './blog';
import onlineWills from './online-wills';
import guard from './guardianship';
import epa from './epa';
import pricing from './pricing';
import contact from './contact';
import charities from './charities';
import premium from './premium';
import comparison from './comparison';
import whichWill from './which-will';
import countrySpecificWills from './country-specific-wills';
import questions from './questions';
import faq from './faq';

export default {
    ...common,
    ...siteMetadata,
    ...home,
    ...about,
    ...blog,
    ...onlineWills,
    ...guard,
    ...epa,
    ...pricing,
    ...contact,
    ...charities,
    ...premium,
    ...comparison,
    ...whichWill,
    ...countrySpecificWills,
    ...questions,
    ...faq,
};
