export default {
    blog: {
        titleHK: 'Will Writing Tips and Advice by Experts | Free Guide',
        titleSG: 'Will Writing Tips and Advice by Experts | Free Guide',
        titleMY: 'Will Writing Tips and Advice by Experts | Free Guide',
        titleUS: 'Will Writing Tips and Advice by Experts | Free Guide',
        descriptionHK: 'Check out our Free Hong Kong estate planning and Will writing guides, as well as articles about guardianship, executors, probate, and more.',
        descriptionSG: 'Check out our Free Singapore estate planning and Will writing guides, as well as articles about guardianship, executors, probate, and more.',
        descriptionMY: 'Check out our Free Malaysia estate planning and Will writing guides, as well as articles about guardianship, executors, probate, and more.',
        descriptionUS: 'Check out our Free US estate planning and Will writing guides, as well as articles about guardianship, executors, probate, and more.',
        keywordsHK: 'How to Write a Will, Hong Kong Will guides, Estate Planning Articles, Estate planning blog, posts',
        keywordsSG: 'How to Write a Will, Singapore Will guides, Estate Planning Articles, Estate planning blog, posts',
        keywordsMY: 'How to Write a Will, Malaysia Will guides, Estate Planning Articles, Estate planning blog, posts',
        keywordsUS: 'How to Write a Will, US Will guides, Estate Planning Articles, Estate planning blog, posts',
        Free: 'Free',
        Guide: ' Guides',
        Search: 'Search',
        posts: ' Posts',
        column: 'Column View',
        list: 'List View',
        toggle: 'toggle view style',
        noFilter: 'No Filter',
        article: 'article',
        guide: 'guide',
        minRead: ' min read',
        articles: 'Articles',
        disc: 'NobleWills has prepared these guides and articles in good faith and are intended to be used as a guide only. None of its statements, claims, or contents should be construed as advice or a recommendation. Inclusion of the name, logo or other identification of any companies on this platform does not constitute endorsement or recommendations of the goods or services of those companies.',
        willGuideTitleHK: 'How do I write my Will Guide | Free Guide',
        willGuideTitleSG: 'How do I write my Will Guide | Free Guide',
        willGuideTitleUS: 'How do I write my Will Guide | Free Guide',
        willGuideDescriptionHK:
            'How to write a Will for Hong Kong guide. Follow this guide as you use the online Will service to understand what is in the Will and what to consider.',
        willGuideDescriptionSG:
            'How to write a Will for Singapore guide. Follow this guide as you use the online Will service to understand what is in the Will and what to consider.',
        willGuideDescriptionUS:
            'How to write a Will for the United States guide. Follow this guide as you use the online Will service to understand what is in the Will and what to consider.',
        willGuideKeywordsHK: 'How to write a Will, Hong Kong Will guide, What is in a will, HK Will service',
        willGuideKeywordsSG: 'How to write a Will, Singapore Will guide, What is in a will, Singapore Will service',
        willGuideKeywordsUS: 'How to write a Will, American Will guide, What is in a will, American Will service',
        'guide-how-do-i-write-my-will': 'How do I write my Will?',
        willGuideDescription: "Want to write your Will and don't know where to start? This collection of articles will help guide you through the process from start to finish.",
    },
};
