export default {
    blog: {
        titleHK: '建立平安紙小貼士及建議 | 網誌',
        descriptionHK: '指南的用意是助您理解更多關於遺產計劃和訂立平安紙的認識，當中包括有關監護權，平安紙執行人，平安紙認證等的文章。',
        keywordsHK: '如何訂立平安紙, 如何訂立平安紙, 香港平安紙指南, 遺產文章, 遺產計劃網誌, 帖文',
        Free: '免費',
        Guide: '指南',
        Search: '搜尋',
        posts: ' 帖文',
        column: '欄表',
        list: '列表',
        toggle: '切換樣式',
        noFilter: '沒有篩選',
        article: '文章',
        guide: '指南',
        minRead: ' 分鐘閱讀',
        articles: '文章',
        disc: 'NobleWills 真誠地準備的指南和文章僅供參考。 其聲明、指引或內容均不應構成專家意見或推薦。 在本平台上包含任何公司的名稱、標識或其他辨認均不構成對這些公司的商品或服務的認可或推薦。',
        willGuideTitleHK: '如何撰寫我的平安紙指南 | 參考文章',
        willGuideTitleSG: '如何撰寫我平安紙指南| 參考文章',
        willGuideDescriptionHK: '如何撰寫我的平安紙香港指南。 請按照本指南使用我們的網上服務以了解平安紙中的內容以及應考慮的事項。',
        willGuideKeywordsHK: '如何撰寫我的平安紙，香港平安紙指南，平安紙的內容，香港平安紙服務',
        'guide-how-do-i-write-my-will': '如何撰寫我的平安紙?',
        willGuideDescription: '想訂立平安紙卻不知如何開始？ 這一系列文章將能助您由頭到尾了解及完成整個過程。',
    },
};
