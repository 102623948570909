export default {
    onlineWills: {
        titleHK: '網上平安紙服務 | 平安紙律師推薦 | 立平安紙律師費用 | 平安紙平安紙分別',
        descriptionHK: 'NobleWills 是香港網上立平安紙的平台，服務快速，廉宜且值得信賴。 只需2個工作天您便可以收取一份專業平安紙。',
        keywordsHK: '香港網上平安紙, 香港平安紙, 平安紙樣本, 香港網上平安紙, 香港平安紙, 平安紙律師介紹, 平安紙律師推薦, 立平安紙律師費用, 平安紙平安紙分別',
        s: '的',
        online: '網上',
        will: '訂立平安紙平台',
        estatePlanning: '輕鬆訂立平安紙，由',
        estatePlanning2: '起。只需要15分鐘，便能建立您的遺產計劃。',
        How: '整個程序如何運作',
        doesThe: '',
        processWork: '',
        eachStep: '每一步都經過深思熟慮，使得您在訂立平安紙時的體驗暢通無阻。我們亦提供有用的提示及相關指南。您知道我們的遺囑專家能與您在線上客服直接對話嗎？',
        step2: '提供基本資料',
        step2Body: '最快15分鐘，便可提供平安紙所需內容。',
        step3: '查閱及簽署',
        step3Body: '在兩個工作天內收到完整的平安紙，並按照指引簽署和確認所有內容。',
        step4: '保持更新',
        step4Body: '由於環境因素不斷變化，您的遺產計劃也需作定期查閱及更新。您可以隨時登入帳戶更改資料，以收取最新版本平安紙。',
        ourWills: '平安紙',
        ourWillsBody: '遺產計劃不單是一份平安紙，當中還包括其他重要的文件。您可以參考我們的遺產計劃組合。',
        whyUse: '為什麼使用我們的網上服務？',
        whyUseBody: 'NobleWills 的網上平安紙訂立服務除了為您節省時間、金錢，亦能減輕您的壓力，我們的',
        whyUseItem: '流程簡潔，能協助您順暢地完成您的平安紙。',
        whyUseItem2: '平安紙內容全面、價格合理，而且沒有隱形費用。',
        whyUseItem3: '網上平台方便實用、資訊豐富、而且由律師精心設計。可讓您安心地在家中建立您的平安紙。',
        cover: '網上平安紙涵蓋些甚麼？',
        coverBody:
            '您可以選擇平安紙執行人/受託人來管理您的遺產、選擇孩子和寵物的監護人，向家人和朋友分發遺贈，並將您的其餘遺產分配給其他人。甚至可以選擇從遺產中排除某些人並安排您的葬禮。',
        coverBody2: '您還可以選擇提供更多資料以製造願望信、資產清單和數碼資產清單。 這些文件對您的平安紙執行人管理您的遺產時非常有用。',
        howLong: '整個過程需時多久？',
        howLongBody: '填寫您平安紙有關的內容只需15分鐘。',
        howLongBody2: '倘若需要更多時間考慮平安紙的內容，我們的系統將保存您的進度，以便您稍後登入以完成該過程。',
        howLongBody3: '在您提交資料後，我們只需2個工作天，便能完成並電郵遺產規劃的PDF版本給您。 ',
        howLongBody4: '最後一步是很簡單地下載最終文件，然後按照說明簽署和存儲您的平安紙。（升級服務：專業版本會送到你府上）',
        whatLang: '文件是用什麼語言寫的？',
        whatLangBodyHK: '平安紙和隨附文件會以您帳戶所選擇的語言而設。',
        preview: '示範片段',
        previewBody: '以下片段示範了平安紙訂立流程。',
        notSuitable: '',
        premiumService: '',
    },
};
