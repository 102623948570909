export default {
    questions: {
        title: '我們開始吧！',
        description: '1-2分鐘的時間完成這份問卷，讓我們協助您選擇最適合您的平安紙服務。',
        takeQuest: '開始問卷',
        questionsRemaining: '最多還有{{quantity}}個問題！',
        complete: '完成了！',
        signUp: '註冊',
        signUpDesc: '已經知道網上遺囑服務適合您？直接跳到下面的註冊頁面。',
    },
};
