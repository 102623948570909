import localizationService from '../../services/localization/localization.service';
import TextUtils from '../../utils/text.utils';
import { PriceFormatOptions } from './types';

export abstract class BasePrice {
    constructor(readonly price: number, readonly currency: string) {}

    protected formattedPriceWithCurrency(price: number, options?: PriceFormatOptions): string {
        return `${localizationService.currencySymbol(this.currency)}${TextUtils.addCommas(price * (options?.factor || 1), options?.decimals)}`;
    }

    get currencySymbol(): string {
        return localizationService.currencySymbol(this.currency);
    }

    formattedPrice(options?: PriceFormatOptions): string {
        return this.formattedPriceWithCurrency(options?.priceOverride ?? this.price, options);
    }

    formattedCouplePrice(options?: PriceFormatOptions): string {
        return this.formattedPriceWithCurrency(options?.priceOverride ?? this.price * 1.5, options);
    }
}
