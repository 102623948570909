export default {
    questions: {
        title: "Let's get started!",
        description: 'Unsure what to do? Take this 1-2 minute questionnaire to help us better determine which Will service is best suited for you.',
        takeQuest: 'Get Started',
        questionsRemaining: '{{quantity}} question(s) max remaining!',
        complete: 'Complete!',
        signUp: 'Sign Up',
        signUpDesc: 'Already know that the Online Will Service is for you? Jump straight to the sign up page below.',
    },
};
