import axios, { AxiosResponse } from 'axios';
import { IContactRequestDto } from '../models/contact/requestDto/contact-request.dto';
import { IDiscount } from '../models/discount/dto/discount.dto';
import { IReview, IReviewStats } from '../models/review/dto/query-review.dto';
import { IUserDataDto } from '../models/user/dto/user.dto';
import { FullLocationEnum } from '../services/localization/localization.service';
import EnvironmentUtils from '../utils/environment.utils';
import { PricingDto } from '../models/pricing/dto/pricing.dto';
import { IAgreementDto } from '../models/agreement/dto/agreement.dto';
import { IQueryAgreementDto } from '../models/agreement/dto/query-agreement.dto';
import { ICreateLeadDto } from '../models/lead/dto/create-lead.dto';
import { ExternalToken } from '../types/models';

class ApiService {
    private static _instance = axios.create({
        baseURL: `${EnvironmentUtils.GATSBY_API_URL}v1/`,
        timeout: 10000,
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
    });

    static hasToken(): boolean {
        return typeof document !== 'undefined' && document.cookie.split(';').some(item => item.trim().startsWith('token='));
    }

    static getAuth(): Promise<AxiosResponse<IUserDataDto>> {
        return this._instance.get<IUserDataDto>('auth');
    }

    static postContact(contactRequest: IContactRequestDto): Promise<void> {
        return this._instance.post('contact', contactRequest);
    }

    static getReviews(): Promise<AxiosResponse<IReview[]>> {
        return this._instance.get<IReview[]>('review');
    }

    static getReviewStats(): Promise<AxiosResponse<IReviewStats>> {
        return this._instance.get<IReviewStats>('review/stats');
    }

    static getPrices(location: FullLocationEnum, externalToken?: ExternalToken | null): Promise<AxiosResponse<PricingDto>> {
        return this._instance.get<PricingDto>(`payment/prices/${location}?externalToken=${externalToken}`);
    }

    static getDiscounts(): Promise<AxiosResponse<IDiscount[]>> {
        return this._instance.get<IDiscount[]>(`discounts/active`);
    }

    static getActiveAgreement(query: IQueryAgreementDto): Promise<AxiosResponse<IAgreementDto>> {
        return this._instance.get<IAgreementDto>(`agreements/active`, {
            params: query,
        });
    }

    static createLead(createLeadDto: ICreateLeadDto): Promise<void> {
        return this._instance.post('leads', createLeadDto);
    }
}

export default ApiService;
