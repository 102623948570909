import { LanguageEnum } from '../../../services/localization/localization.service';
import { ISOTimeStamp } from '../../../types/models';

export type AgreementId = string;

export enum AgreementType {
    TermsOfService = 'Terms of Service',
    PrivacyPolicy = 'Privacy Policy',
    CookiePolicy = 'Cookie Policy',
    PersonalInformationCollectionStatement = 'Personal Information Collection Statement',
}

export enum AgreementStatus {
    Draft = 'draft',
    Active = 'active',
    Inactive = 'inactive',
}

export interface IAgreementDto {
    id: AgreementId;
    type: AgreementType;
    language: LanguageEnum;
    version: string;
    content: string;
    status: AgreementStatus;
    createdDate: ISOTimeStamp;
    effectiveDate?: ISOTimeStamp;
    ineffectiveDate?: ISOTimeStamp;
}
