import React, { createContext, ReactNode, useContext, useEffect, useMemo } from 'react';
import useSWR from 'swr';
import appPackage from '../../package.json';
import ApiService from '../api/api.service';
import { AllChargeableServicesPricesDiscounts } from '../models/pricing/dto/pricing.dto';
import { Pricing } from '../models/pricing/pricing';
import { IUserDto } from '../models/user/dto/user.dto';
import { AllChargeableServices } from '../services/localization/localization.service';
import { useExternalToken } from './use-external-token';
import { useLocaleDetails } from './use-locale-details';

export type NobleWillsProps = {
    isAuth: boolean;
    isLoadingPricing: boolean;
    user?: IUserDto;
    pricing?: Pricing;
};

export const NobleWillsContext = createContext<NobleWillsProps>({} as NobleWillsProps);

interface NobleWillsProviderProps {
    children: ReactNode;
}

const NobleWillsProvider: React.FC<NobleWillsProviderProps> = ({ children }) => {
    const localeDetails = useLocaleDetails();
    const { externalToken } = useExternalToken();
    const { data: userData } = useSWR(ApiService.hasToken() ? 'user' : undefined, () => ApiService.getAuth());
    const { data: priceData, isLoading: isLoadingPricing } = useSWR(`prices/${localeDetails.fullLocationName}/${externalToken?.toString()}`, () =>
        ApiService.getPrices(localeDetails.fullLocationName, externalToken?.toString())
    );
    useEffect(() => {
        const styles = [
            'font-size: 40px',
            'font-family: lato, sans-serif',
            'font-weight: bold',
            'background: linear-gradient(to left, #DABEE2, #42abab)',
            'display: inline-block',
            'color: #fdfdfd',
            'padding: 8px 19px',
            'border-radius: 9px',
            'box-shadow: 6px 6px 0 0 #1a202c',
            'border: 1px solid #1a202c;',
        ].join(';');

        console.log('%cNobleWills', styles);
        console.log(`Home Page Version: ${appPackage.version}`);
    }, []);

    const memoizedValues = useMemo<NobleWillsProps>(
        () => ({
            user: userData?.data?.user,
            isAuth: !!userData?.data?.user,
            pricing: priceData?.data
                ? new Pricing(priceData.data)
                : new Pricing({
                      currency: localeDetails.currency.toLowerCase(),
                      generalDiscount: undefined,
                      services: Object.keys(localeDetails.pricing).reduce<Partial<AllChargeableServicesPricesDiscounts>>((acc, key) => {
                          acc[key as AllChargeableServices] = {
                              originalPrice: localeDetails.pricing[key as AllChargeableServices] as number,
                              discount: undefined,
                              discountedPrice: undefined,
                          };
                          return acc;
                      }, {} as Partial<AllChargeableServicesPricesDiscounts>),
                  }),
            isLoadingPricing,
        }),
        [userData, priceData, isLoadingPricing, localeDetails.locationCode]
    );
    return <NobleWillsContext.Provider value={memoizedValues}>{children}</NobleWillsContext.Provider>;
};

export default NobleWillsProvider;

/**
 * Wrapper context hook to house any desired hook logic
 */
export function useNobleWills(): NobleWillsProps {
    const context = useContext(NobleWillsContext);

    return context;
}
