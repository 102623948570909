export default {
    pricing: {
        titleHK: '香港訂立平安紙服務 | 價目',
        descriptionHK: '單一份平安紙由 1,500 港元，夫妻各一份平安紙由 2,250 港元。 查看價目和網上平安紙服務有關的其他詳情。',
        keywordsHK: '香港平安紙費用,網上平安紙價錢,遺產計劃價格,會籍',
        whatIsThe: '平安紙的',
        pricing: '價格',
        forAWill: '',
        pricingStarts: '價格由',
        andIncludes: '起，修改費用由{{membership}}開始。',
        forCouples: ' 夫妻組合',
        start: '免費開始',
        more: '關於網上平安紙',
        keep: '記得定時更新平安紙',
        upToDate: '',
        membership1:
            '生活環境變化無常，你的平安紙也應隨著時間而修改及更新。只需成為會員，便可隨時登入無限次修改你的平安紙。 對比起傳統的律師樓和平安紙公司所收的昂貴費用合理得多， 首年免費，其後每年{{price}}。',
        membership2: '',
        faq: '關於價格常見問題',
        package: '網上遺產計劃',
        onlineWills: '網上平安紙',
        basicOnlineWills: '基本服務',
        basicOnlineWillsDescription: '只需 15 分鐘，便可即時在網上訂立平安紙，包括免費指導。',
        advancedOnlineWills: '升級服務',
        advancedOnlineWillsDescription: '只需 15 分鐘，便可即時在網上訂立平安紙，並由我們的專家對你的遺產資料進行專業查閱。',
        temporaryGuardianshipDescription: '指定臨時監護人',
        whatsIncluded: '包含',
        availableAddons: '可選附加服務',
        single: '單一份平安紙',
        couple: '夫婦各一份平安紙',
        willIncludes1: '平安紙',
        willIncludes2: '致執行人/受託人的信函（通知他們被委任了）',
        willIncludes3: '願望信',
        willIncludes4: '資產與數碼資產清單',
        willIncludes5: '所有文件的PDF版本',
        willIncludes6: '30日在平台上進行無限次更改',
        willIncludes7: '專業印刷版本送到府上',
        willIncludes8: '為您的平安紙和資產清單進行專業查閱',
        willIncludes9: '以視像模式核實身份及解答疑難',
        willIncludes10: '指定永久監護人',
        willIncludes11: '簽署見證指示',
        editingMembership: '會籍',
        membership: '會籍',
        membershipIncludes1: '無限次對您的平安紙進行修改',
        membershipIncludes2: '由我們的遺囑專家對每次的更新提交進行查閱',
        membershipIncludes3: '持續支援',
        membershipIncludes4: '通知您們有關的法律的變更。',
        witnessing: '見證服務',
        print: '專業印刷版本',
        storageService: '平安紙保管服務',
        countrySpecificWills: '其他國家平安紙',
        discount: '折扣',
        fixedDiscount: '扣減{{currency}}{{discount}}',
        percentDiscount: '{{discount}}%優惠',
        autoApply: '折扣將會自動在結帳時使用。',
    },
};
