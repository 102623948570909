import { DocSectionsEnum } from '../../../../components/services/wills/document-display';
import { AgreementType } from '../../../../models/agreement/dto/agreement.dto';

export default {
    common: {
        period: '.',
        comma: ',',
        'Hong Kong': 'Hong Kong',
        Singapore: 'Singapore',
        Malaysia: 'Malaysia',
        'United States': 'United States',
        start: 'Start',
        'Start Now': 'Start Now',
        'Write my Will': 'Write my Will',
        Login: 'Login',
        'Talk to an expert': 'Talk to an expert',
        Home: 'Home',
        Wills: 'Wills',
        'Online Wills': 'Online Will Service',
        // "Premium Wills": "Premium Wills",
        Pricing: 'Pricing',
        Services: 'Services',
        deedOfTemporaryGuardianship: 'Deed of Temporary Guardianship',
        temporaryGuardianship: 'Temporary Guardianship',
        enduringPowerOfAttorney: 'Enduring Power of Attorney',
        'About Us': 'About Us',
        Blog: 'Blog',
        Charities: 'Charities',
        'Free Guide': 'Free Guides',
        'Charities we support': 'Charities we support',
        Help: 'Help',
        Contact: 'Contact',
        'Contact Us': 'Contact Us',
        'Location Selection': 'Location Selection',
        Free: 'Free',
        'More Details': 'More Details',
        Product: 'Product',
        Price: 'Price',
        'Single Will': 'Single Will',
        'Couple Wills': 'Couple Wills',
        'Editing Membership': 'Editing Membership',
        'Plan your future': 'Plan your future ',
        'with us': 'with us',
        Cookies: 'Cookies',
        usesCookies: 'NobleWills uses cookies to improve our website. See our ',
        forDetails: ' for details.',
        'Wills made easy.': 'Wills made easy.',
        rightsReserved: '© NobleWills 2021 - {{currentYear}}. All Rights Reserved',
        isAWritingService:
            'NobleWills is a will writing service. Although NobleWills is comprised of qualified lawyers, we are not a law firm, tax advisor, nor a substitute for a lawyer’s advice about complex estate planning issues.',
        'Read more': 'Read more',
        Required: 'Required',
        invalidEmail: 'Invalid email address',
        cancel: 'Cancel',
        submit: 'Submit',
        messageSent: 'Message Sent!',
        getBack: 'We will get back to you as soon as possible.',
        '404': '404: Not found',
        '404Description': "This page doesn't exist.",
        '404Body': "Oh no! You've hit a page that doesn't exist",
        takeMeHome: 'Take me home',
        Version: 'Version',
        lastEdit: 'Date',
        HKD: 'HKD',
        SGD: 'SGD',
        ohNo: 'Oh No!',
        errorMessage: "We've run into a problem. Try again shortly.",
        Newsletter: 'Newsletter',
        newsletterBody: 'Provide your name, email, and country of residence to receive our newsletter!',
        Frequently: 'Frequently',
        asked: ' asked questions',
        FAQ: 'FAQ',
        faqLong: 'Frequently asked questions',
        searchFaqsAndArticles: 'Search FAQs and Articles',
        'Get in touch': 'Get in touch',
        'Help center': 'Help center',
        Contents: 'Contents',
        free: 'Free',
        [DocSectionsEnum.introduction]: DocSectionsEnum.introduction,
        introductionBody: 'A breakdown of the different sections of our estate planning package and their contents. Select each section in the dropdown menu to view more.',
        [DocSectionsEnum.instructions]: DocSectionsEnum.instructions,
        instructionsBody:
            'This contains information on how to make your Will valid and outlines the subsequent steps for completing your estate planning package, such as signing the accompanying documents and where to store your Will.',
        [DocSectionsEnum.letterToTheExecutors]: DocSectionsEnum.letterToTheExecutors,
        executorsBody:
            'This is a letter for you to forward to your Executors and Trustees, so they know you have entrusted them to take on this important role. It also contains helpful information for them regarding their responsibilities and next steps.',
        [DocSectionsEnum.lastWillAndTestament]: DocSectionsEnum.lastWillAndTestament,
        willBody:
            'This is your actual Will document, where you appoint your executors and trustees to manage your estate, choose guardians if you have children or pets, and state the distribution of your estate.',
        [DocSectionsEnum.letterOfWishes]: DocSectionsEnum.letterOfWishes,
        wishesBody: 'If you list out any wishes during the process, then we will create this letter for you to keep with your Will.',
        [DocSectionsEnum.listOfAssets]: DocSectionsEnum.listOfAssets,
        assetsBody:
            "This list is optional but helpful for making sure that your executors and trustees know what you own, saving them the time and resources typically needed to find one's assets.",
        [DocSectionsEnum.listOfDigitalAssets]: DocSectionsEnum.listOfDigitalAssets,
        digitalAssetsBody: 'This list is also optional but if you have valuable assets online, then this would help your executors and trustees access and manage them.',
        seeReviews: 'See all reviews',
        previous: 'previous',
        next: 'next',
        Lawfirms: 'Law firms',
        TraditionalWillWriter: 'Traditional Will Writing Firm',
        OnlineTemplate: 'Online Template',
        Process: 'Process',
        TimeToComplete: 'Time to Complete',
        Accuracy: 'Accuracy',
        Updates: 'Updates',
        noblePrice: 'Online Will service for {{currency}}{{basic}} or {{currency}}{{advanced}}.',
        noblePrice2: 'Premium service for {{currency}}{{premium}}+',
        noblePrice3: 'Major discounts when purchasing together as a couple.',
        lawFirmPrice: 'Charge per hour or by the value of your assets.',
        lawFirmPrice2: 'Often includes hidden fees for more options.',
        willWriterPrice: '{{currency}}{{premium}}+',
        templatePrice: 'Free',
        nobleProcess: 'Online Will service: Create an account, fill in details, and pay, in just 15 minutes.',
        nobleProcess2:
            'Premium service: Video call to gather information. Signing and witnessing at an agreed location. The Wills are drafted individually by our Wills specialists. Updates of related documents can be done via our online platform.',
        lawFirmProcess: 'Multiple in-person consultations and meetings with lawyers to understand your background.',
        lawFirmProcess2: 'Multiple back and forth emails to finalize the documents.',
        lawFirmProcess3: 'Arrange another date to finalize the Wills in the law firm.',
        willWriterProcess: 'Consultation with specialists to understand your background.',
        willWriterProcess2: 'Face-to-face meetings and multiple back and forth emails to finalize the documents.',
        willWriterProcess3: 'Arrange another date to finalize the Wills in the office.',
        templateProcess:
            'Conduct heaps of research to understand the process, the laws, the content, and the necessary clauses to achieve what you want, and without any real certainty. Spend extensive amounts of time finding the right template to fill in. Hope for the best!',
        nobleTime: 'Online Will service: Within 2 working days.',
        nobleTime2: 'Premium service: Within 7 days.',
        lawFirmTime: 'Weeks',
        willWriterTime: 'Weeks',
        templateTime: 'Possibly weeks between researching, finding the right template, and carefully entering the necessary information.',
        nobleAccu: 'Our Will specialists have legal backgrounds and are members of worldwide estate planning societies.',
        nobleAccu2: 'We also specialize in cross border estate planning and can write Wills for numerous jurisdictions.',
        lawFirmAccu: 'Most law firms do not specialize in Will writing, and therefore lack the in depth expertise.',
        lawFirmAccu2: 'They are also restricted from doing cross border estate planning and cannot write Wills for overseas assets.',
        willWriterAccu: 'Many Will writing companies do not have legal backgrounds.',
        templateAccu: 'There is no certainty that your research is accurate in making the Will legally binding.',
        nobleUpdates: 'Online Will service: Editing membership at ${{editing}} per annum with unlimited updates for all documents.',
        nobleUpdates2: 'Premium service: Starting from ${{editing}} per year.',
        lawFirmUpdates: 'Physically return to the law firm and pay the original fee of the Will again.',
        willWriterUpdates: 'Physically return to the Will writing firm and pay the original fee of the Will or the original fee with a small discount.',
        templateUpdates: 'Manually research the correct legal wordings and terms to make your own adjustments and customizations.',
        comparison: 'See how NobleWills compares with other options out there.',
        compare: 'Comparison',
        questionnaire: 'Unsure which Will service is best for you? Take our quick questionnaire.',
        questionnaireAction: 'Find Out Now',
        personalReviewHeading: 'A Human Touch',
        personalReview:
            "We don't leave your family's future up to bots. All our documents are personally reviewed by our own Will specialists, who are themselves qualified lawyers (and real humans).",
        countrySpecificWills: 'Country-Specific Online Will Service',
        countrySpecificWills2: 'Country-Specific Online Will',
        whatDoINeed: 'What Do I Need?',
        myAccount: 'My Account',
        includesSigning: 'Limited Time: Includes signing and witnessing in Wanchai office!',
        advancedSigning: 'Select services include signing and witnessing in Wanchai office!',
        valuedAt: 'Valued at {{price}}',
        witnessTiming: 'From 2pm-6pm Mon-Fri',
        daysRemaining: '{{days}} Days Left',
        expired: 'Expired',
        specialDeal: 'Special Deal through November!',
        [AgreementType.TermsOfService]: AgreementType.TermsOfService,
        [AgreementType.PrivacyPolicy]: AgreementType.PrivacyPolicy,
        failedToLoad: 'Failed to load',
        tryAgain: 'Try again',
        tryHelpCenter: 'Have questions? Try our help center',
        clear: 'Clear',
        lookingForHelp: 'Looking for help?',
        helpBody:
            "Check out our help center for answers to common questions or our guides to help you navigate the estate planning process. Still can't find what you're looking for? Send us a message!",
        inPartnership: 'In Partnership With',
    },
};
