import { Box, Flex, Heading, Select, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Image from '../../common/image';

interface ISection {
    title: string;
    description: string;
    index: number;
}

export enum DocSectionsEnum {
    introduction = 'Introduction',
    instructions = 'Instructions',
    letterToTheExecutors = 'Letter to the Executors',
    lastWillAndTestament = 'Last Will and Testament',
    letterOfWishes = 'Letter of Wishes (optional)',
    listOfAssets = 'List of Assets (optional)',
    listOfDigitalAssets = 'List of Digital Assets (optional)',
}

const DOCUMENT_SECTIONS: ISection[] = [
    {
        title: DocSectionsEnum.introduction,
        description: 'introductionBody',
        index: 10,
    },
    {
        title: DocSectionsEnum.instructions,
        description: 'instructionsBody',
        index: 9,
    },
    {
        title: DocSectionsEnum.letterToTheExecutors,
        description: 'executorsBody',
        index: 7,
    },
    {
        title: DocSectionsEnum.lastWillAndTestament,
        description: 'willBody',
        index: 6,
    },
    {
        title: DocSectionsEnum.letterOfWishes,
        description: 'wishesBody',
        index: 1,
    },
];

export interface DocumentDisplayProps {}

const DocumentDisplay: React.FC<DocumentDisplayProps> = () => {
    const [index, setIndex] = useState<number>(0);
    const { t } = useTranslation();

    return (
        <Flex gap={3} flexWrap="wrap" alignItems="flex-start" justifyContent="center">
            <Box pos="relative" mx="auto" w="100%" maxW="md" h={['3xs', 'xs', 'sm']} mb="10" px="2">
                {[...new Array(12)].reverse().map((_, i: number) => {
                    return (
                        <Box
                            key={i}
                            w="100%"
                            maxW={['36%', '3xs', '2xs']}
                            pos="absolute"
                            top="0"
                            right={`${i * 20}px`}
                            shadow="md"
                            transition="all 500ms ease-in-out"
                            transform={i >= DOCUMENT_SECTIONS[index].index ? 'translateY(100%)' : 'translateY(0)'}
                            opacity={i >= DOCUMENT_SECTIONS[index].index ? '0' : '1'}
                        >
                            <Image src={`will/will-${10 - i}.jpg`} alt={`NobleWills Will document ${i + 1}`} directory="images" />
                        </Box>
                    );
                })}
            </Box>

            <Box maxW="lg" w="100%" mx="auto" px="2">
                <Heading as="h3" size="md" mb="5">
                    {t('Contents')}
                </Heading>
                <Select
                    name="section"
                    id="section"
                    textTransform="capitalize"
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                        setIndex(Number(e.target.value));
                    }}
                    value={index}
                    mb="5"
                >
                    {DOCUMENT_SECTIONS.map((section: ISection, i: number) => (
                        <option key={i} value={i}>
                            {i + 1}. {t(section.title)}
                        </option>
                    ))}
                </Select>
                <Text textAlign="justify" fontSize="xl">
                    {t(DOCUMENT_SECTIONS[index].description)}
                </Text>
            </Box>
        </Flex>
    );
};

export default DocumentDisplay;
