exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-guide-how-do-i-write-my-will-tsx": () => import("./../../../src/pages/blog/guide-how-do-i-write-my-will.tsx" /* webpackChunkName: "component---src-pages-blog-guide-how-do-i-write-my-will-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-charities-tsx": () => import("./../../../src/pages/charities.tsx" /* webpackChunkName: "component---src-pages-charities-tsx" */),
  "component---src-pages-comparison-tsx": () => import("./../../../src/pages/comparison.tsx" /* webpackChunkName: "component---src-pages-comparison-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-help-center-tsx": () => import("./../../../src/pages/help-center.tsx" /* webpackChunkName: "component---src-pages-help-center-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-lead-tsx": () => import("./../../../src/pages/lead.tsx" /* webpackChunkName: "component---src-pages-lead-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-services-country-specific-wills-tsx": () => import("./../../../src/pages/services/country-specific-wills.tsx" /* webpackChunkName: "component---src-pages-services-country-specific-wills-tsx" */),
  "component---src-pages-services-deed-of-temporary-guardianship-tsx": () => import("./../../../src/pages/services/deed-of-temporary-guardianship.tsx" /* webpackChunkName: "component---src-pages-services-deed-of-temporary-guardianship-tsx" */),
  "component---src-pages-services-enduring-power-of-attorney-tsx": () => import("./../../../src/pages/services/enduring-power-of-attorney.tsx" /* webpackChunkName: "component---src-pages-services-enduring-power-of-attorney-tsx" */),
  "component---src-pages-services-online-wills-tsx": () => import("./../../../src/pages/services/online-wills.tsx" /* webpackChunkName: "component---src-pages-services-online-wills-tsx" */),
  "component---src-pages-services-premium-wills-tsx": () => import("./../../../src/pages/services/premium-wills.tsx" /* webpackChunkName: "component---src-pages-services-premium-wills-tsx" */),
  "component---src-pages-terms-of-service-tsx": () => import("./../../../src/pages/terms-of-service.tsx" /* webpackChunkName: "component---src-pages-terms-of-service-tsx" */),
  "component---src-pages-which-will-service-is-best-for-me-[node]-tsx": () => import("./../../../src/pages/which-will-service-is-best-for-me/[node].tsx" /* webpackChunkName: "component---src-pages-which-will-service-is-best-for-me-[node]-tsx" */),
  "component---src-templates-blog-template-tsx": () => import("./../../../src/templates/blog-template.tsx" /* webpackChunkName: "component---src-templates-blog-template-tsx" */),
  "component---src-templates-charity-template-tsx": () => import("./../../../src/templates/charity-template.tsx" /* webpackChunkName: "component---src-templates-charity-template-tsx" */),
  "component---src-templates-faq-collection-template-tsx": () => import("./../../../src/templates/faq-collection-template.tsx" /* webpackChunkName: "component---src-templates-faq-collection-template-tsx" */)
}

