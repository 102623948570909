import { useMemo } from 'react';
import localization, { AllChargeableServices, ILocaleDetails } from '../services/localization/localization.service';
import { usePageContext } from '../services/localization/page-context';

type LocaleDetails = ILocaleDetails & {
    localizeLink: (path: string) => string;
    hasService: (service: AllChargeableServices) => boolean;
};

/**
 * Provides location specific details
 */
export function useLocaleDetails(): LocaleDetails {
    const { lang } = usePageContext();

    return useMemo<LocaleDetails>(
        () => ({
            ...localization.getLocaleDetails(lang || ''),
            localizeLink: path => localization.localizeLink(path, lang || ''),
            hasService: service => localization.hasService(service, lang || ''),
        }),
        [lang]
    );
}
