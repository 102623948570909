class TextUtils {
    static addCommas(number: number | undefined, decimals?: number): string {
        if (number === undefined) return '';
        return number.toFixed(decimals || 0).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }

    static capitalizeFirstWord(text: string): string {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    static kebabCase(text: string): string {
        return text
            .replace(/([a-z])([A-Z])/g, '$1-$2')
            .replace(/\s+/g, '-')
            .toLowerCase();
    }
}
export default TextUtils;
