export default {
    home: {
        titleHK: 'Hong Kong Will Writing Service',
        titleSG: 'Singapore Will Writing Service',
        titleMY: 'Malaysian Will Writing Service',
        titleUS: 'American Will Writing Service',
        descriptionHK: 'Hong Kong online Will writing service that is fast, easy-to-use and affordable, designed by qualified Lawyers, Will specialists, and STEP members.',
        descriptionSG: 'Singapore online professional Will writing service that is fast, easy-to-use and affordable, designed by qualified Lawyers and STEP members.',
        descriptionMY: 'Malaysian online professional Will writing service that is fast, easy-to-use and affordable, designed by qualified Lawyers and STEP members.',
        descriptionUS: 'American online professional Will writing service that is fast, easy-to-use and affordable, designed by qualified Lawyers and STEP members.',
        keywordsHK: ', Hong Kong Will Writing, Professional Wills Writing Services hongkong, Online will writer hong kong, expert will writer hong kong',
        keywordsSG: ', professional will singapore, Singapore Professional Will Writing, Online Will Writing Service Singapore, professional will writers Singapore',
        keywordsMY: ', Malaysia Will Writing, Professional Wills Writing Services Malaysia, Online will writer Malaysia, expert will writer Malaysia',
        keywordsUS:
            ', New York Will, California Will, Texas Will, Florida Will, Pennsylvania Will, Illinois Will, American Professional Will Writing, Online Will Writing Service US',
        inc1: 'Guardians for your children',
        inc1Body: 'Ensure that your children will be protected',
        inc2: 'Protection for your partner',
        inc2Body: 'Ensure your partner is sufficiently provided for',
        inc3: 'Legal in {{country}}',
        inc3Body: 'Our Wills are based on precedence and are kept up to date',
        inc4: 'Data protection',
        inc4Body: 'Your information is encrypted and will never be shared',
        inc5: 'Real human support',
        inc5Body: 'Speak with our Will specialists when you need help',
        inc6: 'Legacy donations',
        inc6Body: 'Leave a legacy by giving back to charities in your Will',
        inc7: 'Protect your pets',
        inc7Body: 'Make sure all members of your family are looked after',
        inc8: 'Assets list',
        inc8Body: 'Organize all your assets and help your executors find what you own',
        inc9: 'Gift giving',
        inc9Body: 'Give away items, properties, or cash gifts to loved ones',
        inc10: 'Unlimited updates',
        inc10Body: 'Keep your Will up to date with our editing membership',
        s: "'s",
        online: 'Online',
        will: 'Will',
        writers: 'Writers',
        headingSubtitle: "Plan for your family's future online. ",
        headingSubtitle2: 'Professionally written Wills starting from ',
        headingSubtitle3: '.',
        headingSubtitle4: 'Reviewed by real humans.',
        notJustTemplate: "We're not just a template",
        notJustTemplateBody:
            'NobleWills is an easy-to-use online Will writing platform that strives to offer the most comprehensive and seamless Will writing service at a reasonable price. Writing your Will with us is a step by step process with plenty of guidance and support along the way.',

        onlineService: 'Online Will service',
        onlineServiceBody:
            "Writing and maintaining your Will just got much easier with NobleWill's online Will writing service. Answer some questions about yourself and your wishes in as little as 15 minutes to receive an estate planning bundle custom tailored for you.",
        premiumServiceBody:
            'Plan your worldwide estate with the personalized guidance of our Will writing experts. Write Wills for every country where you own assets, each with their own customizations to fit your specific wishes, save on taxes, and speed up the process for your family.',
        onlineServiceHow: 'How does it work?',
        onlineServicePrice: 'What is the price?',
        attributes: 'Why use NobleWills?',
        personal: 'Personalized attention',
        personalBody:
            'Help from real Will writing experts is always a click away. When you use our on-site chat window, our Will specialists will personally tend to your questions and concerns.',
        personalBody2:
            'Have a question about the process? Want advice on who you should appoint as a guardian? Or perhaps you just want to give some feedback? Simply click on the green chat button in the bottom right corner of the screen to speak with us!',
        personalBody3: 'We are available Monday to Friday',
        chat: 'Chat with us',
        guidesBody:
            'Our Will writing experts have written useful articles about general estate planning, guides to help you with our online Will writing service, and explanations about the contents of our products.',
        moreGuides: 'More Guides',
        charitiesBody:
            'Did you know that you can give away part of your estate or even cash gifts to charities when you use our online Will service? NobleWills is proud to support these charities and we hope you will consider doing so too!',

        aWill: 'Ours Wills include',
        Pricing: 'Pricing',
        atAGlance: ' at a glance',
        trusted: 'Trusted',
        trustedBody: 'Your data is encrypted and the Wills are based off precedence and are carefully written by qualified Lawyers and STEP members.',
        fast: 'Fast',
        fastBody: 'Complete the questions in as little as 15 minutes. Receive the completed Will within 2 working days.',
        affordable: 'Affordable',
        affordableBody: 'Flat fees starting from {{single}} for a single Will. {{couple}} for couple Wills. Unlimited edits for {{membership}} per year.',

        partner: 'Invite your partner',
        partnerBody: 'Save time and money when doing your Will together with your partner!',
        partnerBody2: 'How does it work?',
        partnerBody3: 'Complete the online Will process for yourself.',
        partnerBody4: 'Invite your partner and share details you have in common.',
        partnerBody5: 'Select to pay for you and your partner at a discounted rate.',
        getStarted: 'Get started',
        reviews: 'Reviews',
        inTheNews: 'As seen in',
    },
};
