import EnvironmentUtils from '../../utils/environment.utils';
import { GTagEvent } from './types';

export class GtagService {
    static event(event: GTagEvent | string, params?: Gtag.CustomParams | Gtag.ControlParams | Gtag.EventParams | undefined) {
        if (EnvironmentUtils.isProd) {
            gtag('event', event, params);
        }
    }
}
