import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';

const theme = extendTheme({
    components: {
        Input: {
            baseStyle: {
                field: {
                    borderColor: 'darkGrey',
                    color: 'willBlack.500',
                    background: 'background',
                    backgroundColor: 'background',
                },
            },
            defaultProps: {
                size: 'lg',
                focusBorderColor: 'willGreen.500',
            },
        },
        Textarea: {
            baseStyle: {
                borderColor: 'darkGrey',
                field: {
                    borderColor: 'darkGrey',
                    color: 'darkGrey',
                    background: 'background',
                    backgroundColor: 'background',
                    paddingTop: '6',
                    paddingBottom: '6',
                },
            },
            defaultProps: {
                focusBorderColor: 'willGreen.500',
                borderColor: 'darkGrey',
            },
        },
        Select: {
            baseStyle: {
                field: {
                    borderColor: 'darkGrey',
                    color: 'darkGrey',
                    background: 'background',
                    backgroundColor: 'background',
                },
            },
            defaultProps: {
                size: 'lg',
                focusBorderColor: 'willGreen.500',
            },
        },
        FormLabel: {
            baseStyle: {
                fontWeight: '900',
                color: 'darkGrey',
            },
        },
        Checkbox: {
            baseStyle: {
                borderRadius: '9px',
            },
            defaultProps: {
                size: 'lg',
                colorScheme: 'willGreen',
            },
        },
        Link: {
            baseStyle: {
                borderRadius: '9px',
            },
        },
        Button: {
            baseStyle: {
                borderRadius: '9px',
                overflow: 'hidden',
                fontWeight: 'semibold',
            },
            defaultProps: {
                colorScheme: 'willGreen',
                size: 'lg',
            },
        },
    },
    colors: {
        background: '#FDFDFD',
        backgroundGrey: '#EAEAEA',
        white: '#FDFDFD',
        darkGrey: '#545E75',
        mediumGrey: '#E5E5E5',
        lightGrey: '#F5F5F5',
        darkGreen: '#42ABAB',
        darkPink: '#DABEE2',
        willDarkGrey: {
            50: '#edf1fe',
            100: '#d0d6e3',
            200: '#b4bbcb',
            300: '#97a0b4',
            400: '#7a859e',
            500: '#616b85',
            600: '#4b5368',
            700: '#343c4b',
            800: '#1e2430',
            900: '#070c17',
        },
        willBlack: {
            50: '#6E7583',
            100: '#303a4e',
            200: '#303a4e',
            300: '#1A202C',
            400: '#1A202C',
            500: '#1A202C',
            600: '#1A202C',
            700: '#050c15',
            800: '#050c15',
            900: '#050c15',
        },
        willPink: {
            50: '#f8ecfb',
            100: '#DABEE2',
            200: '#DABEE2',
            300: '#DABEE2',
            400: '#DABEE2',
            500: '#DABEE2',
            600: '#DABEE2',
            700: '#DABEE2',
            800: '#DABEE2',
            900: '#DABEE2',
        },
        willGreen: {
            50: '#89cccc',
            100: '#c2eded',
            200: '#a0dfdf',
            300: '#7cd2d2',
            400: '#5ac4c4',
            500: '#42abab',
            600: '#318686',
            700: '#205f5f',
            800: '#0d3a3a',
            900: '#001515',
        },
        green: {
            500: '#42abab',
        },
    },
    shadows: {
        outline: '0 0 0 3px rgba(66, 171, 171, 0.6)',
    },
    fonts: {
        body: 'Lato, sans-serif',
        heading: 'raleway, sans-serif',
    },
    breakpoints: createBreakpoints({
        sm: '30em',
        md: '62em',
        lg: '80em',
        xl: '80em',
        '2xl': '96em',
    }),
});

export default theme;
