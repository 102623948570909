import { useLocation, WindowLocation } from '@reach/router';

type UseCustomLocationReturn = WindowLocation<unknown> & {
    isReady: boolean;
};
export function useCustomLocation(): UseCustomLocationReturn {
    const location = useLocation();

    return {
        ...location,
        isReady: typeof window !== 'undefined',
    };
}
