import { PartnerCode } from './dto/pricing.dto';
import { BasePrice } from './base-price';
import { PriceFormatOptions } from './types';

export class PartnerDiscountedPrice extends BasePrice {
    readonly originalPrice: number;
    readonly partnerCode: PartnerCode;

    constructor(price: number, originalPrice: number, currency: string, partnerCode: PartnerCode) {
        super(price, currency);
        this.originalPrice = originalPrice;
        this.partnerCode = partnerCode;
    }

    get colorTheme(): string {
        switch (this.partnerCode) {
            case PartnerCode.AIA:
                return '#d32047';
            case PartnerCode.BOWTIE:
                return '#FF0068';
            default:
                return '#42abab';
        }
    }

    formattedOriginalPrice(options?: PriceFormatOptions): string {
        return this.formattedPriceWithCurrency(options?.priceOverride ?? this.originalPrice, options);
    }

    formattedCouplePrice(options?: PriceFormatOptions): string {
        return this.formattedPriceWithCurrency(options?.priceOverride ?? this.originalPrice * 1.5 - (this.originalPrice - this.price), options);
    }
}
