import { WillCountry } from '../../../../data/will-countries/will-countries';

export default {
    premium: {
        premiumWills: '綜合平安紙服務',
        premiumWills2: '綜合平安紙服務',
        heading: '綜合平安紙服務',
        heading2: '',
        subHeading: '無論您有多國資產，還是想要完全度身訂造的遺產計劃，我們的綜合服務都能提供完善的計劃。',
        chatNow: '立即聯絡我們',
        productSub: '夫妻組合價格 {{price}}',
        includes1: '平安紙',
        includes2: '為子女任命永久和臨時監護人',
        includes3: '給執行人的信函（通知他們被委任了）',
        includes4: '意願書',
        includes5: '資產和數碼資產清單',
        includes6: '一次遺產計劃諮詢會議',
        includes7: '所有文件的專業打印',
        includes8: '所有文件的簽署和見證',
        descriptionHeading: '我們的綜合服務適合您嗎？',
        description: '如果網上平安紙服務不適合您，或者需要一份度身訂造的平安紙來涵蓋您的獨特情況，建議你選擇我們的綜合平安紙服務。',
        description2: '我們擁有廣泛的跨境資產規劃經驗，可以按照您的資產背景處理不同的情況。',
        description3: '我們可以為以下司法管轄區撰寫平安紙：',
        description4: '** 這個列表並非所有。只需讓我們知道您需要哪些國家的平安紙，我們將盡力為您提供協助。請注意，所有綜合服務的平安紙都以英語撰寫。 **',
        how: '如何運作？',
        initial: '初次會面',
        initialBody: '一小時的會面，我們的遺囑專家將會收集您的資料，並按照你的意願提供適當的跨國／遺產計劃建議。',
        drafting: '文件起草',
        draftingBody: '會議後，我們的團隊將會在兩至三個工作天內完成並提供文件給您查閱。',
        sign: '簽署和見證',
        signBody: '在您滿意文件內容後，便可以安排簽署見證。',
        [WillCountry.unitedKingdom]: '英國',
        [WillCountry.australia]: '澳洲',
        [WillCountry.unitedStates]: '美國',
        [WillCountry.singapore]: '新加坡',
        [WillCountry.malaysia]: '馬來西亞',
        [WillCountry.india]: '印度',
        [WillCountry.caymanIslands]: '開曼群島',
        [WillCountry.jersey]: '澤西島',
        [WillCountry.guernsey]: '根西島',
        [WillCountry.isleOfMan]: '曼島',
        [WillCountry.thailand]: '泰國',
        [WillCountry.vietnam]: '越南',
        [WillCountry.philippines]: '菲律賓',
        // [WillCountry.mainlandChina]: 'Mainland China',
        [WillCountry.japan]: '日本',
        [WillCountry.hongKong]: '香港',
        [WillCountry.canada]: '加拿大',
        [WillCountry.newZealand]: '新西蘭',
        [WillCountry.taiwan]: '台灣',
        [WillCountry.britishVirginIslands]: '英屬維京群島',
        [WillCountry.france]: '法國',
        [WillCountry.germany]: '德國',
        [WillCountry.italy]: '意大利',
        [WillCountry.gibraltar]: '直布羅陀',
        [WillCountry.luxembourg]: '盧森堡',
        [WillCountry.ireland]: '愛爾蘭',
        [WillCountry.israel]: '以色列',
        [WillCountry.sweden]: '瑞典',
        [WillCountry.switzerland]: '瑞士',
        [WillCountry.southAfrica]: '南非',
    },
};
