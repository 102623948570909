import { useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';
import { IconType } from 'react-icons';
import { CookiesService } from '../../services/cookies/cookies.service';
import Banner, { BannerProps } from './banner';
import React from 'react';

type EventBannerProps = BannerProps & {
    endDate: Date;
    icon: IconType;
    colorScheme: string;
    onClose?: () => void;
};

const EVENT_BANNER_HIDDEN = 'eventBannerHidden';
const EventBanner: React.FC<EventBannerProps> = ({ endDate, icon, colorScheme, onClose, children }) => {
    const bannerDisclosure = useDisclosure();

    useEffect(() => {
        const eventBannerHidden = new CookiesService().get<boolean>(EVENT_BANNER_HIDDEN);
        if (!eventBannerHidden && new Date().getTime() < endDate.getTime()) {
            bannerDisclosure.onOpen();
        }
    }, [endDate]);

    return bannerDisclosure.isOpen ? (
        <Banner
            id="event-banner"
            icon={icon}
            colorScheme={colorScheme}
            pos="fixed"
            left={0}
            top="70px"
            width={'100%'}
            zIndex={3}
            onClose={() => {
                new CookiesService().set(EVENT_BANNER_HIDDEN, true, { expires: endDate });
                bannerDisclosure.onClose();
                onClose && onClose();
            }}
        >
            {children}
        </Banner>
    ) : (
        <></>
    );
};

export default EventBanner;
