import localizationService, { AllChargeableServices } from '../../services/localization/localization.service';
import { Discount } from '../discount/discount';
import { DiscountedPrice } from './discounted-price';
import { PricingDto } from './dto/pricing.dto';
import { NormalPrice } from './normal-price';
import { PartnerDiscountedPrice } from './partner-discounted-price';
import { PriceDetails } from './types';

export class Pricing {
    private _services: PricingDto['services'];
    private _currency: PricingDto['currency'];
    private _generalDiscount: Discount | undefined;
    private _allActiveDiscounts: Discount[] = [];

    constructor(pricingDto: PricingDto) {
        this._services = pricingDto.services;
        this._currency = pricingDto.currency;
        this._generalDiscount = pricingDto.generalDiscount ? new Discount(pricingDto.generalDiscount) : undefined;

        Object.values(this._services).forEach(service => {
            if (service?.discount) {
                this._allActiveDiscounts.push(new Discount(service.discount));
            }
        });
    }

    get currencySymbol(): string {
        return localizationService.currencySymbol(this._currency);
    }

    get generalDiscount(): Discount | undefined {
        return this._generalDiscount ?? this._allActiveDiscounts[0];
    }

    hasPrice(service: AllChargeableServices): boolean {
        return !!this._services[service];
    }

    getDiscountForService(service: AllChargeableServices): Discount | undefined {
        const servicePrice = this._services[service];
        return servicePrice?.discount ? new Discount(servicePrice.discount) : undefined;
    }

    getPriceDetailsForService(service: AllChargeableServices): PriceDetails | undefined {
        const price = this._services[service];
        if (!price) return undefined;
        if (price.partner && price.discountedPrice !== undefined) {
            return new PartnerDiscountedPrice(price.discountedPrice, price.originalPrice, this._currency, price.partner);
        } else if (price.discount && price.discountedPrice !== undefined) {
            return new DiscountedPrice(price.discountedPrice, price.originalPrice, this._currency, new Discount(price.discount));
        } else {
            return new NormalPrice(price.originalPrice, this._currency);
        }
    }
}
