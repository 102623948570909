import { Component, ErrorInfo, ReactNode } from 'react';
import { Box, Text, Heading, Button, VStack } from '@chakra-ui/react';
import ShadowBox from './shadow-box';
import LocalizedLink from './localized-link';
import RouteEnum from '../../routing/routes';
import { LanguageEnum } from '../../services/localization/localization.service';
import React from 'react';

interface Props {
    lang?: LanguageEnum;
    children: ReactNode;
}

interface State {
    hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
    public state: State = {
        hasError: false,
    };

    public static getDerivedStateFromError(_: Error): State {
        return { hasError: true };
    }

    public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.error('Uncaught error:', error, errorInfo);
    }

    render() {
        const isEnglish = this.props.lang === LanguageEnum.en;
        if (this.state.hasError) {
            return (
                <Box minH="100vh" px="3" pb="16" pt="32">
                    <VStack as={ShadowBox} bgColor="background" spacing={3} p={[4, 8]} w="100%" maxW="md" mx="auto">
                        <Heading size="lg">{isEnglish ? 'Oh No!' : '糟糕了！'}</Heading>
                        <Text my="3" textAlign={'center'}>
                            {isEnglish ? 'It looks like something has gone wrong.' : '似乎出了一些問題。'}
                            <br />
                            {isEnglish ? 'Try reloading the page to fix the issue.' : '嘗試重新加載頁面以解決問題。'}
                        </Text>
                        <Button onClick={() => window.location.reload()}>{isEnglish ? 'Reload' : '重新載入'}</Button>
                        {isEnglish ? (
                            <Text textAlign="center" mt={6} display="block">
                                If the problem persists, <LocalizedLink to={`${RouteEnum.contact}/`}>contact support</LocalizedLink> for help.
                            </Text>
                        ) : (
                            <Text textAlign="center" mt={6} display="block">
                                如果問題仍然存在，請
                                <LocalizedLink to={`${RouteEnum.contact}/`}>聯絡支援</LocalizedLink>
                            </Text>
                        )}
                    </VStack>
                </Box>
            );
        }
        return <>{this.props.children}</>;
    }
}

export default ErrorBoundary;
