import { CloseButton, HStack, Icon, StackProps, Text } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import React from 'react';

export type BannerProps = StackProps & {
    icon: IconType;
    colorScheme: string;
    onClose?: () => void;
};

const Banner: React.FC<BannerProps> = ({ icon, children, colorScheme, onClose, ...props }) => {
    return (
        <HStack justifyContent={'center'} alignItems="center" w="100%" py={4} px={[2, 2, 3, 6]} background={`${colorScheme}.100`} {...props}>
            <Icon as={icon} fontSize="2xl" color={`${colorScheme}.800`} />
            <Text pl="0" fontWeight="600" color={`${colorScheme}.800`}>
                {children}
            </Text>
            {onClose && (
                <CloseButton
                    onClick={e => {
                        e.stopPropagation();
                        onClose();
                    }}
                    color={`${colorScheme}.800`}
                />
            )}
        </HStack>
    );
};

export default Banner;
