export default {
    epa: {
        title: 'Enduring Power of Attorney',
        description: 'Appoint someone to manage your finances in the event of mental incapacity with a Hong Kong enduring power of attorney document for HK$2,000.',
        keywords: 'Hong Kong enduring power of attorney',
        heading: 'Enduring ',
        heading2: 'Power ',
        heading3: 'of ',
        heading4: 'Attorney',
        subHeading: 'Ensure your family has access to your finances if you become mentally incapacitated',
        productSub: 'Inclusive of doctor and lawyer fees',
        includes1: 'Initial video call to take instructions',
        includes2: 'PDF version of the Enduring Power of Attorney',
        includes3: 'Professional print of the document',
        includes4: 'Meeting to sign and witness the document with doctor and lawyer',
        descriptionHeading: 'How does it work?',
        descriptionBody:
            'An enduring power of attorney ("EPA") is a document that allows you to choose someone to manage your finances in the event that you are mentally incapacitated.',
        descriptionBody2:
            "If you were to become mentally incapacitated for any amount of time and didn't have an EPA, then nobody would legally be able to access your assets or finances to maintain your family expenses during the ordeal. With an EPA, the person that you appoint (known as an attorney) can gain access to your assets in order to manage expenses on your behalf such as bills, loans, mortgage payments, medical bills, and other daily family expenses.",
        descriptionBody3: "Checkout our FAQ or Help Center below for more information about EPA's.",
    },
};
