export default {
    about: {
        titleHK: 'Professional Will Writing Company in Hong Kong | About Us',
        titleSG: 'Professional Will Writing Company in Singapore | About Us',
        titleMY: 'Professional Will Writing Company in Malaysia | About Us',
        titleUS: 'Professional American Will Writing Company | About Us',
        descriptionHK: 'NobleWills is an online professional Will writing company in Hong Kong, complete with guidance and review by Will specialists.',
        descriptionSG: 'NobleWills is an online professional Will writing company in Singapore, complete with guidance and review by Will specialists.',
        descriptionMY: 'NobleWills is an online professional Will writing company in Malaysia, complete with guidance and review by Will specialists.',
        descriptionUS: 'NobleWills is an online professional Will writing company in the US, complete with guidance and review by Will specialists.',
        keywordsHK: 'Hong Kong Will Writing Experts, Estate Planner, Online Will Writing Service, Will Writing Company hong kong',
        keywordsSG: 'Singapore Professional Will Writing Service, Estate Planner, Online Will Writing Service',
        keywordsMY: 'Malaysia Professional Will Writing Service, Estate Planner, Online Will Writing Service',
        keywordsUS: 'United States Professional Will Writing Service, Estate Planner, Online Will Writing Service',
        About: 'About',
        Us: 'Us',
        theCompany: 'The Company',
        coFounders: 'The Co-Founders',
        aboutBody:
            'Writing a will has traditionally been costly, time-consuming, and stressful. Our mission at NobleWills is to transform this experience into something straightforward, accessible and empowering.',
        aboutBody2:
            'At NobleWills, we understand the significance of estate planning and the importance of having a will in place. Our company was born out of the desire to simplify this crucial process and make it more approachable for everyone. We recognised the barriers people faced when attempting to create a will - the complexities, expenses, and emotional strain. Thus, we embarked on a mission to change this narrative.',
        aboutBody3:
            'Whether you prefer the convenience of our online services or the personal touch of face-to-face interaction, NobleWills caters to your needs. Our online platform provides a user-friendly experience, guiding you through the process step-by-step. No more confusion or overwhelming legal jargon – our interface ensures clarity and simplicity.',
        aboutBody4:
            'Moreover, our commitment to supporting you doesn’t end with the creation of your will. NobleWills provides lifelong updates, secure document storage, and a comprehensive blog covering wills, estates, and inheritance.',
        aboutBody5: 'We believe securing your legacy should be stress-free and affordable. NobleWills empowers you to take charge of your future without the usual obstacles.',
        aboutBody6:
            "Join us at NobleWills, where we simplify will writing, guide you through the process, and make estate planning effortless and empowering. Your legacy matters, and we're here to help protect it.",
        Sarah: 'Sarah Ip',
        Brian: 'Brian MacPherson',
        ceo: 'CEO and Co-Founder',
        aboutSarah:
            'Sarah was raised in both the UK and Hong Kong, and became a qualified lawyer in 2018. She is also an affiliate member of the Society of Trust and Estate Practitioners and currently serves as a consultant lawyer for CPH Legal HK.',
        aboutBrian:
            "Brian, originally from New York, has been living in Hong Kong since 2013. He began his journey in software development in 2016 and has since devoted his time and passion to creating products that enhance people's experiences.",
        cto: 'CTO and Co-Founder',
        partners: 'Strategic Partners',
    },
};
