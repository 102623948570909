import { forwardRef } from '@chakra-ui/react';
import { Link as GatsbyLink } from 'gatsby';
import React from 'react';
import localizationService from '../../services/localization/localization.service';
import { usePageContext } from '../../services/localization/page-context';

export interface LocalizedProps {
    to: string;
}

const LocalizedLink = forwardRef<LocalizedProps, 'a'>(({ to, ...otherProps }, ref) => {
    const { lang } = usePageContext();

    let transformedTo = localizationService.localizeLink(to, lang);

    return <GatsbyLink ref={ref} {...otherProps} to={transformedTo} />;
});

export default LocalizedLink;
