export default {
    countrySpecificWills: {
        title: '其他國家平安紙',
        description: '保護您的資產：度身訂造平安紙。定制符合您所選國家法律要求的平安紙。',
        keywords: '其他國家平安紙，國際平安紙，法律要求，資產保護，受益人，資產規劃，定制平安紙，平穩過渡，專家指導',
        heading: '其他國家的',
        heading2: '',
        heading3: '網上平安紙服務',
        dash: '',
        subHeading: '保護您全球各地資產，並為您的家人節省時間，金錢和體力。與我們一同撰寫不同國家的平安紙，並完成您的遺產計劃。',
        productSub: '夫妻優惠',
        descriptionHeading: '國家列表',
        descriptionBody: '如果您沒有看到適合的國家，請聯絡我們。請注意，以下所有平安紙是以英語撰寫。',
    },
};
