import { ChakraProvider, CSSReset } from '@chakra-ui/react';
import '@fontsource/lato';
import '@fontsource/noto-sans-tc';
import '@fontsource/raleway/500.css';
import React, { ReactNode } from 'react';
import { I18nextProvider } from 'react-i18next';
import { SWRConfig } from 'swr';
import customTheme from './src/@chakra-ui/gatsby-plugin/theme';
import CurrentEventBanner from './src/components/banners/current-event-banner';
import ErrorBoundary from './src/components/common/error-boundary';
import GoogleScripts from './src/components/scripts/google-scripts';
import ABTestingProvider from './src/hooks/use-ab-testings';
import MessengerProvider from './src/hooks/use-messenger';
import NobleWillsProvider from './src/hooks/use-noblewills';
import LocalizationService from './src/services/localization/localization.service';
import { IPageContext, PageContextProvider } from './src/services/localization/page-context';
import './src/styles/global.css';

type WrapRootElementProps = {
    element: ReactNode;
};

type WrapPageElementProps = WrapRootElementProps & {
    props: {
        pageContext: IPageContext;
    };
};

export const wrapRootElement: React.FC<WrapRootElementProps> = ({ element }) => {
    return (
        <ChakraProvider theme={customTheme}>
            <I18nextProvider i18n={LocalizationService.i18n}>
                <SWRConfig
                    value={{
                        errorRetryCount: 2,
                        revalidateOnFocus: false,
                    }}
                >
                    <MessengerProvider>
                        <CSSReset />
                        {element}
                        <GoogleScripts />
                    </MessengerProvider>
                </SWRConfig>
            </I18nextProvider>
        </ChakraProvider>
    );
};

export const wrapPageElement: React.FC<WrapPageElementProps> = ({ element, props }) => {
    return (
        <ErrorBoundary lang={props.pageContext.languageCode}>
            <PageContextProvider value={props.pageContext}>
                <NobleWillsProvider>
                    <ABTestingProvider>
                        <CurrentEventBanner />
                        {element}
                    </ABTestingProvider>
                </NobleWillsProvider>
            </PageContextProvider>
        </ErrorBoundary>
    );
};
