import { PartnerCode } from '../pricing/dto/pricing.dto';

export class ExternalToken {
    private static readonly _EXTERNAL_TOKEN = 'externalToken';

    readonly partnerCode: PartnerCode;
    readonly token: string;

    private constructor(readonly rawToken: string) {
        const [partnerCode, token] = rawToken.split(':');
        this.partnerCode = partnerCode as PartnerCode;
        this.token = token;
    }

    toString(): string {
        return this.rawToken;
    }

    toJSON(): string {
        return this.rawToken;
    }

    static fromString(token: string | null | undefined): ExternalToken | undefined {
        if (!token || !ExternalToken.isValid(token)) return undefined;
        return new ExternalToken(token);
    }

    static isValid(token: string | undefined): boolean {
        if (!token) return false;
        const components = token.split(':');
        if (components.length !== 2) return false;
        const [partnerCode] = components;
        return Object.values(PartnerCode).includes(partnerCode as PartnerCode);
    }

    static storeExternalToken(token: string): void {
        localStorage.setItem(ExternalToken._EXTERNAL_TOKEN, token);
    }

    static getExternalToken(): ExternalToken | undefined {
        const token = localStorage.getItem(ExternalToken._EXTERNAL_TOKEN);
        return ExternalToken.fromString(token || undefined);
    }
}
