import { AllChargeableServices, FullLocationEnum } from '../../../services/localization/localization.service';
import { Email, ISOTimeStamp } from '../../../types/models';
import { UserId } from '../../user/dto/user.dto';

export enum DiscountType {
    percentage = 'percentage',
    fixed = 'fixed',
}

export type DiscountId = string;

export interface IDiscount {
    _id: DiscountId;
    isActive: boolean;
    isPrivate: boolean;
    name: string;
    description: string;
    discount: number;
    discountType: DiscountType;
    emails: Email[];
    services: AllChargeableServices[];
    locations: FullLocationEnum[];
    chineseName?: string;
    chineseDescription?: string;
    link?: string;
    startDate: ISOTimeStamp;
    endDate: ISOTimeStamp;
    createdAt: ISOTimeStamp;
    updatedAt: ISOTimeStamp;
    createdBy: UserId;
    updatedBy: UserId;
}
