enum RouteEnum {
    home = '/',
    about = '/about',
    services = '/services',
    deedOfTemporaryGuardianship = '/deed-of-temporary-guardianship',
    enduringPowerOfAttorney = '/enduring-power-of-attorney',
    onlineWills = '/online-wills',
    countrySpecificWills = '/country-specific-wills',
    premiumWills = '/premium-wills',
    listOfAssets = '/list-of-assets',
    charities = '/charities',
    blog = '/blog',
    willGuide = '/guide-how-do-i-write-my-will',
    contact = '/contact',
    helpCenter = '/help-center',
    privacyPolicy = '/privacy-policy',
    termsOfService = '/terms-of-service',
    comparison = '/comparison',
    whichWillServiceIsBestForMe = '/which-will-service-is-best-for-me',
}

export default RouteEnum;
