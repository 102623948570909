export default {
    about: {
        titleHK: '香港訂立平安紙 | 關於我們 ',
        descriptionHK: 'NobleWills 是香港首間包括遺囑專家提供指導和查閱的專業網上訂立平安紙平台。',
        keywordsHK: '香港訂立遺囑專家, 遺產計劃師, 網上訂立平安紙服務, 香港訂立平安紙公司, 平安紙',
        About: '關於',
        Us: '我們',
        theCompany: '本公司',
        coFounders: '聯合創辦人',
        aboutDescription: 'NobleWills 是一個容易使用的網上訂立平安紙平台。',
        aboutBody: '傳統觀念中，撰寫平安紙成本高、費時且令人感到壓力。我們 NobleWills的使命就是將這種體驗轉化為簡單直接、更自主。',
        aboutBody2:
            'NobleWills明白遺產規劃及平安紙的重要性，我們就是為了簡化這個流程而成立，志在令大眾更容易接觸遺產規劃。我們明白，普遍在立平安紙時遇到的困難——複雜難明、支出費用和情感上的壓力。因此，我們的使命就是改變這種現象。',
        aboutBody3:
            '無論您喜歡我們的網上服務還是真人面對面服務，NobleWills 都能滿足您所需。我們的網上平台提供方便易用的體驗，引導您逐步完成整個過程。不再有令人苦惱或難明的法律術語——我們的介面絕對清晰、簡潔易明。',
        aboutBody4: '此外，我們對您的服務承諾並不止於完成平安紙， NobleWills 提供終身修改服務、儲存文件服務以及一系列涵蓋遺囑、遺產及傳承的參考文章。',
        aboutBody5: '我們相信保障自己的遺產應該是輕鬆而負擔得起，NobleWills 助您排除障礙，掌控自己的未來。',
        aboutBody6: '歡迎選用NobleWills，我們將簡化撰寫的流程，引導您輕鬆完成訂立平安紙，讓遺產規劃變得簡潔有力，就讓我們協助您保障您的財富。',
        Sarah: '葉媛瓊',
        Brian: '麥智仁',
        ceo: '首席執行總裁兼聯合創辦人 ',
        aboutSarah: '葉媛瓊在英國和香港長大，於 2018 年獲得律師牌照。她是國際信託及資產規劃學會的成員，也是陳柏豪律師事務所的顧問律師。語言：英文、廣東話',
        aboutBrian: '麥智仁在美國紐約長大，並自 2013 年起一直居住於香港。他在 2016 年開始軟件研發，並將所有時間和精力投入到研發產品以改善大眾的體驗。',
        cto: '首席技術總監兼聯合創辦人',
        partners: '策劃夥伴',
    },
};
