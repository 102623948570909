export default {
    countrySpecificWills: {
        title: 'Country-Specific Wills',
        description: 'Secure Your Legacy: Country-Specific Wills Tailored to Your Needs. Create a will customized to the legal requirements of your chosen country.',
        keywords:
            'country-specific wills, international wills, legal requirements, asset protection, beneficiary, estate planning, customized will, smooth transition, expert guidance',
        heading: 'Country',
        heading2: 'Specific',
        heading3: 'Wills',
        dash: '-',
        subHeading:
            'Secure your international assets and save your family time and money. Your main Will can cover your worldwide assets, but if you have assets in other countries, you may want a will for each country.',
        productSub: 'Special pricing for couples',
        descriptionHeading: 'Available Countries',
        descriptionBody:
            'This is a non-exhaustive list of countries for which we offer country-specific Wills. If you do not see your country listed, please contact us to see if we can help you. Please note that all country-specific Wills are written in English only.',
    },
};
