export default {
    charities: {
        titleHK: '受惠慈善機構 | 慈善機構',
        descriptionHK: 'NobleWills 不斷為慈善機構在公眾上提高意識，並同時鼓勵客戶捐贈部份遺產幫助不同的慈善機構。',
        keywordsHK: '香港慈善機構, 遺產捐贈, NobleWills 合作伙伴',
        charities: '慈善機構',
        weSupport: '所支持的',
        charityBody:
            'NobleWills 細心挑選以下的慈善機構，接受遺產捐贈。您亦可指明其他團體或者自己所支持的慈善機構。 只需點擊幾下就能將部分的遺產捐贈給有需要的組織。請伸出緩手，捐贈遺產予有需要的組織。',
        areYou: '成為受惠慈善機構',
        areYouBody: '不妨與我們合作，加入我們的受惠名單，以使我們的客戶可考慮捐贈部分遺產予貴機構。',
        donateToday: '立即捐款',
        donationFigures: '承蒙樂助，至今已有{{currency}}{{million}}遺產將會捐出。',
    },
};
