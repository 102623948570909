import { Discount } from '../discount/discount';
import { BasePrice } from './base-price';
import { PriceFormatOptions } from './types';

export class DiscountedPrice extends BasePrice {
    private readonly _discount: Discount;
    readonly originalPrice: number;

    constructor(price: number, originalPrice: number, currency: string, discount: Discount) {
        super(price, currency);
        this.originalPrice = originalPrice;
        this._discount = discount;
    }

    get discount(): Discount {
        return this._discount;
    }

    formattedOriginalPrice(options?: PriceFormatOptions): string {
        return this.formattedPriceWithCurrency(options?.priceOverride ?? this.originalPrice, options);
    }

    formattedCouplePrice(options?: PriceFormatOptions): string {
        return this.formattedPriceWithCurrency(options?.priceOverride ?? this.originalPrice * 1.5 - (this.originalPrice - this.price), options);
    }
}
