export default {
    guard: {
        titleHK: '香港臨時監護人 | 兒童法定監護香港',
        descriptionHK: '香港臨時監護權契約只需HK${{price}}，您便可以選擇信任的人來照顧你的子女。',
        keywordsHK: '兒童法定監護香港, 臨時監護人香港, 香港臨時監護人, 臨時監護權契約, 孩子監護人, 監護人',
        heading: '選擇臨時監護人',
        heading2: '',
        heading3: '',
        subHeading: '臨時監護權契約能在緊急關頭，確保您的子女得到指定人選照顧。',
        productSub: '每個家庭只需一份',
        includes1: '選擇最多兩對伴侶作為子女的臨時監護人',
        includes2: '文件的PDF英文版本',
        includes3: '簽署和見證文件的指南',
        descriptionHeading: '臨時監護權契約有什麼作用？',
        description: '在不可預料的情況下，您的臨時監護權契約可確保您的子女得到保護和照顧。您可以指定信任的朋友或家人作為您子女的臨時監護人。',
        descriptionList: '這份文件會在以下情況適用：',
        description2: '您突發性入院、隔離、失踪、滯留在國外或無法聯絡時；',
        description3: '在您不幸離世時，臨時監護人將會照顧您的子女，直至您平安紙中所委任的永久監護人能夠接收子女。',
    },
};
