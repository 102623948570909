import moment from 'moment';
import { useMemo } from 'react';
import { MdConstruction } from 'react-icons/md';
import { useLocaleDetails } from '../../hooks/use-locale-details';
import EventBanner from './event-banner';
import React from 'react';

type CurrentEventBannerProps = {};

const CurrentEventBanner: React.FC<CurrentEventBannerProps> = () => {
    const { isEnglish } = useLocaleDetails();
    const eventEndDate = useMemo(() => moment({ year: 2024, month: 5, day: 12 }).endOf('day'), []);
    return (
        <EventBanner icon={MdConstruction} colorScheme="red" endDate={eventEndDate.toDate()}>
            {isEnglish
                ? `Our system will be undergoing maintenance until ${eventEndDate.format('MMMM Do YYYY, h:mm a')} HKT. We apologize for any inconvenience caused.`
                : `我們的系統正在進行維修中，直至${eventEndDate.format('YYYY年M月D日 h:mm a')} HKT。造成您使用服務的不便，我們深感抱歉。`}
        </EventBanner>
    );
};

export default CurrentEventBanner;
