import { CloseIcon } from '@chakra-ui/icons';
import { HStack, Icon, IconButton, Link, Text, useDisclosure, VStack } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MdCookie } from 'react-icons/md';
import RouteEnum from '../../routing/routes';
import { CookiesService } from '../../services/cookies/cookies.service';
import LocalizedLink from '../common/localized-link';

interface CookiesBannerProps {
    onHide: () => void;
}

const COOKIE_BANNER_HIDDEN = 'cookieBannerHidden';
const CookiesBanner: React.FC<CookiesBannerProps> = ({ onHide }) => {
    const bannerDisclosure = useDisclosure();
    const { t } = useTranslation();

    useEffect(() => {
        if (!bannerDisclosure.isOpen) {
            const cookieBannerHidden = new CookiesService().get<boolean>(COOKIE_BANNER_HIDDEN);
            if (!cookieBannerHidden) {
                bannerDisclosure.onOpen();
            } else {
                onHide();
            }
        }
    }, [bannerDisclosure.isOpen]);

    const closeBanner = () => {
        const expires = new Date();
        expires.setDate(expires.getDate() + 365);
        new CookiesService().set(COOKIE_BANNER_HIDDEN, true, { expires });
        bannerDisclosure.onClose();
        onHide();
    };

    return (
        <>
            {bannerDisclosure.isOpen && (
                <HStack pos="fixed" bottom={0} left={0} zIndex={2} w="100%" bg="gray.100" spacing={3} justifyContent="center" pl={3} pr={14} py={3}>
                    <Icon as={MdCookie} color="gray.800" boxSize={8} />
                    <VStack spacing={0} alignItems="flex-start">
                        <Text fontWeight={'bold'} textAlign={'left'} color="gray.800" fontSize={['md', 'lg']}>
                            {t('Cookies')}
                        </Text>
                        <Text textAlign={'left'} color="gray.800" fontSize={['sm', 'md']} whiteSpace="pre-line">
                            {t('usesCookies')}
                            <Link fontWeight="bold" as={LocalizedLink} to={`${RouteEnum.privacyPolicy}/`} onClick={closeBanner}>
                                {t('Privacy Policy')}
                            </Link>
                            {t('forDetails')}
                        </Text>
                    </VStack>
                    <IconButton pos="absolute" right={2} variant={'unstyled'} aria-label="close banner" icon={<CloseIcon boxSize={4} color="gray.800" />} onClick={closeBanner} />
                </HStack>
            )}
        </>
    );
};

export default CookiesBanner;
