export default {
    contact: {
        titleHK: '聯絡我們',
        descriptionHK: '讓我們的遺囑專家、和 STEP 成員協助回答您與遺產計劃和訂立平安紙相關的問題。',
        keywordsHK: '聯絡訂立遺囑專家，香港網上遺產計劃，對話，電話，電郵',
        guide: '訂立平安紙是一件不能避免的事，就讓我們的遺囑專家幫助您。',
        emailUs: '使用電郵聯絡我們',
        email: '電郵',
        emailDescription: '選擇使用電郵？點擊這裡取得聯繫。',
        chat: '對話',
        chatWith: '與我們對話',
        chatNow: '點擊此處立即與我們的專家對話。',
        firstName: '姓',
        lastName: '名',
        phone: '電話',
        phoneNumber: '電話號碼',
        phoneCall: '致電我們',
        phoneDescription: '即時通話。',
        name: '姓名',
        message: '信息',
        messagePlaceholder: '你好，我有興趣寫平安紙。',
        callNow: '立即致電給我們',
        country: '國家',
        countryOf: '居住國家',
        countrySelect: '選擇國家',
        stay: '保持更新',
        stayBody: 'NobleWills 會定期推出最新資訊通知客戶最新產品、服務更新、合作夥伴關係等。如果客戶不想收到本公司最新資訊，可以隨時取消訂閱。',
        join: '訂閱 NobleWills 最新資訊',
        whatsapp: 'WhatsApp',
        whatsappUs: 'WhatsApp 我們',
        whatsappDescription: '與我們在 WhatsApp 即時對話',
        mailList: '請將我加入到郵寄名單!',
        receiveNewsletter: '我想接收電子郵件資訊。',
        welcome: '歡迎!',
        thankYou: '謝謝!',
    },
};
