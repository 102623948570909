import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Cookies from 'universal-cookie';
import en from './translations/en';
import tc from './translations/tc';

export enum DocumentServiceEnum {
    basicLastWillAndTestament = 'Basic Last Will and Testament',
    advancedLastWillAndTestament = 'Advanced Last Will and Testament',
    premiumLastWillAndTestament = 'Premium Last Will and Testament',
    countrySpecificWill = 'Country Specific Will',
    // advanceDirective = 'Advance Directive',
    enduringPowerOfAttorney = 'Enduring Power of Attorney',
    deedOfTemporaryGuardianship = 'Deed of Temporary Guardianship',
}

export enum MembershipEnum {
    editingMembership = 'Editing Membership',
    // storageMembership = 'Storage Membership',
}

export enum AddonServiceEnum {
    professionalPrint = 'Professional Print',
    membershipReactivation = 'Membership Reactivation',
    overseasShipping = 'Overseas Shipping',
    basicToAdvancedWillUpgrade = 'Basic to Advanced Will Upgrade',
    witness = 'Witnessing Service',
}

export type OneTimeChargeableServices = DocumentServiceEnum | MembershipEnum;
export type AllChargeableServices = OneTimeChargeableServices | AddonServiceEnum;

export interface ILocaleDetails {
    isDefault: boolean;
    localeCode: LocaleEnum;
    fullLanguageName: FullLanguageEnum;
    fullLocationName: FullLocationEnum;
    fullDisplayLocationName: string;
    alternateName?: string;
    currency: string;
    currencySymbol: string;
    shorthandLanguageName: ShorthandLanguageEnum;
    emoji: string;
    buttonText: string;
    translationCode: LanguageEnum;
    locationCode: LocationEnum;
    isEnglish: boolean;
    isoCountryLang: ISOCountryLangEnum;
    pricing: Partial<Record<AllChargeableServices | 'basicCouple' | 'advancedCouple' | 'premiumWillCouple' | 'premiumEditingMembership', number>>;
    updatePriceComparison: string;
    hours: string;
    dateFormat: string;
    donationsRaised: string;
    services: DocumentServiceEnum[];
}

export enum LanguageEnum {
    en = 'en',
    tc = 'tc',
}

export enum LocaleEnum {
    enHK = 'en-hk',
    zhHK = 'zh-hk',
    enSG = 'en-sg',
    enUS = 'en-us',
    enMY = 'en-my',
}

export enum LocationEnum {
    hk = 'hk',
    sg = 'sg',
    us = 'us',
    my = 'my',
}

export enum FullDisplayLocationEnum {
    hk = 'Hong Kong',
    sg = 'Singapore',
    us = 'America',
    my = 'Malaysia',
}

export enum FullLocationEnum {
    hongKong = 'Hong Kong',
    singapore = 'Singapore',
    malaysia = 'Malaysia',
    unitedStates = 'United States',
}

export enum FullLanguageEnum {
    en = 'English',
    tc = '繁體中文',
}

export enum ShorthandLanguageEnum {
    en = 'En',
    tc = '繁體',
}

export enum ISOCountryLangEnum {
    en = 'en',
    tw = 'tw',
    zh = 'zh',
}

class LocalizationService {
    private readonly cookieKey = 'locale';
    private readonly cookies: Cookies;
    private readonly resources = {
        en,
        tc,
    };
    private _localeServices = new Map<LocaleEnum | string, AllChargeableServices[]>([
        [
            LocaleEnum.enHK,
            [
                DocumentServiceEnum.basicLastWillAndTestament,
                DocumentServiceEnum.advancedLastWillAndTestament,
                DocumentServiceEnum.premiumLastWillAndTestament,
                DocumentServiceEnum.countrySpecificWill,
                DocumentServiceEnum.deedOfTemporaryGuardianship,
                DocumentServiceEnum.enduringPowerOfAttorney,
                AddonServiceEnum.witness,
                AddonServiceEnum.professionalPrint,
            ],
        ],
        [
            LocaleEnum.enSG,
            [
                DocumentServiceEnum.basicLastWillAndTestament,
                DocumentServiceEnum.advancedLastWillAndTestament,
                DocumentServiceEnum.premiumLastWillAndTestament,
                DocumentServiceEnum.countrySpecificWill,
                DocumentServiceEnum.deedOfTemporaryGuardianship,
                AddonServiceEnum.professionalPrint,
            ],
        ],
        [
            LocaleEnum.enMY,
            [
                DocumentServiceEnum.basicLastWillAndTestament,
                DocumentServiceEnum.advancedLastWillAndTestament,
                DocumentServiceEnum.premiumLastWillAndTestament,
                DocumentServiceEnum.countrySpecificWill,
                DocumentServiceEnum.deedOfTemporaryGuardianship,
                AddonServiceEnum.professionalPrint,
            ],
        ],
        [
            LocaleEnum.enUS,
            [
                DocumentServiceEnum.basicLastWillAndTestament,
                DocumentServiceEnum.advancedLastWillAndTestament,
                DocumentServiceEnum.premiumLastWillAndTestament,
                DocumentServiceEnum.countrySpecificWill,
                // DocumentServiceEnum.deedOfTemporaryGuardianship,
                // DocumentServiceEnum.enduringPowerOfAttorney,
            ],
        ],
        [
            LocaleEnum.zhHK,
            [
                DocumentServiceEnum.basicLastWillAndTestament,
                DocumentServiceEnum.advancedLastWillAndTestament,
                DocumentServiceEnum.premiumLastWillAndTestament,
                DocumentServiceEnum.countrySpecificWill,
                DocumentServiceEnum.deedOfTemporaryGuardianship,
                DocumentServiceEnum.enduringPowerOfAttorney,
                AddonServiceEnum.witness,
                AddonServiceEnum.professionalPrint,
            ],
        ],
    ]);

    constructor() {
        i18n.use(initReactI18next).init({
            resources: this.resources,
            defaultNS: 'common',
            fallbackLng: LanguageEnum.en,
            interpolation: {
                escapeValue: false,
            },
            react: {
                useSuspense: false,
                bindI18n: false,
            },
        });

        this.cookies = new Cookies();
    }

    get i18n(): typeof i18n {
        return i18n;
    }

    get defaultLocale(): string {
        return LocaleEnum.enHK;
    }

    get availableLanguages(): string[] {
        return Object.keys(this.resources);
    }

    get getLocaleDetailsByLocationCode(): Map<string, ILocaleDetails[]> {
        return Object.values(LocaleEnum).reduce((accum: Map<string, ILocaleDetails[]>, locale: string) => {
            const localeDetails = this.getLocaleDetails(locale);
            // Remove Chinese Temporarily
            // if (localeDetails.translationCode === LanguageEnum.tc) return accum;

            const currentLocaleDetails = accum.get(localeDetails.locationCode);
            if (!!currentLocaleDetails) {
                accum.set(localeDetails.locationCode, [...currentLocaleDetails, localeDetails]);
            } else {
                accum.set(localeDetails.locationCode, [localeDetails]);
            }
            return accum;
        }, new Map<string, ILocaleDetails[]>());
    }

    public hasService(service: AllChargeableServices, locale?: string): boolean {
        if (!locale) return false;
        const services = this._localeServices.get(locale);
        if (!services) return false;
        return services.includes(service);
    }

    public setLocaleCookie(locale: string): void {
        const expires = new Date();
        expires.setDate(expires.getDate() + 365);

        this.cookies.set(this.cookieKey, locale, {
            path: '/',
            domain: process.env.NODE_ENV === 'production' ? '.noblewills.com' : 'localhost',
            expires,
        });
    }

    public getLocaleCookie(): string | undefined {
        return this.cookies.get(this.cookieKey);
    }

    public getLocaleDetails(locale: string): ILocaleDetails {
        let emoji: string;
        switch (locale) {
            case LocaleEnum.zhHK:
                emoji = '🇭🇰';
                return {
                    isDefault: false,
                    localeCode: LocaleEnum.zhHK,
                    fullLanguageName: FullLanguageEnum.tc,
                    fullLocationName: FullLocationEnum.hongKong,
                    shorthandLanguageName: ShorthandLanguageEnum.tc,
                    translationCode: LanguageEnum.tc,
                    locationCode: LocationEnum.hk,
                    fullDisplayLocationName: '香港',
                    alternateName: '香港',
                    currency: 'HKD',
                    currencySymbol: 'HK$',
                    emoji,
                    buttonText: `${emoji} | ${ShorthandLanguageEnum.tc}`,
                    isEnglish: false,
                    isoCountryLang: ISOCountryLangEnum.tw,
                    pricing: {
                        [DocumentServiceEnum.basicLastWillAndTestament]: 1500,
                        basicCouple: 2250,
                        [DocumentServiceEnum.advancedLastWillAndTestament]: 3000,
                        advancedCouple: 4500,
                        [MembershipEnum.editingMembership]: 300,
                        [DocumentServiceEnum.deedOfTemporaryGuardianship]: 1200,
                        [DocumentServiceEnum.enduringPowerOfAttorney]: 7500,
                        [AddonServiceEnum.witness]: 1000,
                        [DocumentServiceEnum.premiumLastWillAndTestament]: 8000,
                        premiumWillCouple: 10000,
                        premiumEditingMembership: 950,
                        [DocumentServiceEnum.countrySpecificWill]: 3000,
                    },
                    updatePriceComparison: '4,000+',
                    hours: '上午9:30 - 下午6:00pm',
                    dateFormat: 'YYYY年MMMDo',
                    donationsRaised: '120萬',
                    services: [
                        DocumentServiceEnum.basicLastWillAndTestament,
                        DocumentServiceEnum.advancedLastWillAndTestament,
                        DocumentServiceEnum.deedOfTemporaryGuardianship,
                        DocumentServiceEnum.enduringPowerOfAttorney,
                    ],
                };
            case LocaleEnum.enSG:
                emoji = '🇸🇬';
                return {
                    isDefault: false,
                    localeCode: LocaleEnum.enSG,
                    fullLanguageName: FullLanguageEnum.en,
                    fullLocationName: FullLocationEnum.singapore,
                    shorthandLanguageName: ShorthandLanguageEnum.en,
                    translationCode: LanguageEnum.en,
                    locationCode: LocationEnum.sg,
                    fullDisplayLocationName: 'Singapore',
                    alternateName: 'Singapore',
                    currency: 'SGD',
                    currencySymbol: 'S$',
                    emoji,
                    buttonText: `${emoji} | ${ShorthandLanguageEnum.en}`,
                    isEnglish: true,
                    isoCountryLang: ISOCountryLangEnum.en,
                    pricing: {
                        [DocumentServiceEnum.basicLastWillAndTestament]: 260,
                        basicCouple: 390,
                        [DocumentServiceEnum.advancedLastWillAndTestament]: 520,
                        advancedCouple: 780,
                        [MembershipEnum.editingMembership]: 50,
                        [DocumentServiceEnum.deedOfTemporaryGuardianship]: 210,
                        [DocumentServiceEnum.premiumLastWillAndTestament]: 1400,
                        premiumWillCouple: 1750,
                        premiumEditingMembership: 170,
                        [DocumentServiceEnum.countrySpecificWill]: 520,
                    },
                    updatePriceComparison: '170 - 700+',
                    hours: '9:30am SGT to 6:00pm SGT',
                    dateFormat: 'Do MMM, YYYY',
                    donationsRaised: '202,000',
                    services: [
                        DocumentServiceEnum.basicLastWillAndTestament,
                        DocumentServiceEnum.advancedLastWillAndTestament,
                        DocumentServiceEnum.deedOfTemporaryGuardianship,
                        DocumentServiceEnum.premiumLastWillAndTestament,
                    ],
                };
            case LocaleEnum.enMY:
                emoji = '🇲🇾';
                return {
                    isDefault: false,
                    localeCode: LocaleEnum.enMY,
                    fullLanguageName: FullLanguageEnum.en,
                    fullLocationName: FullLocationEnum.malaysia,
                    shorthandLanguageName: ShorthandLanguageEnum.en,
                    translationCode: LanguageEnum.en,
                    locationCode: LocationEnum.my,
                    fullDisplayLocationName: FullDisplayLocationEnum.my,
                    alternateName: FullLocationEnum.malaysia,
                    currency: 'MYR',
                    currencySymbol: 'RM',
                    emoji,
                    buttonText: `${emoji} | ${ShorthandLanguageEnum.en}`,
                    isEnglish: true,
                    isoCountryLang: ISOCountryLangEnum.en,
                    pricing: {
                        [DocumentServiceEnum.basicLastWillAndTestament]: 900,
                        basicCouple: 1350,
                        [DocumentServiceEnum.advancedLastWillAndTestament]: 1800,
                        advancedCouple: 2700,
                        [MembershipEnum.editingMembership]: 180,
                        [DocumentServiceEnum.deedOfTemporaryGuardianship]: 715,
                        [DocumentServiceEnum.premiumLastWillAndTestament]: 4888,
                        premiumWillCouple: 6088,
                        premiumEditingMembership: 600,
                        [DocumentServiceEnum.countrySpecificWill]: 1800,
                    },
                    updatePriceComparison: '600 - 2,500+',
                    hours: '9:30am MYT to 6:00pm MYT',
                    dateFormat: 'Do MMM, YYYY',
                    donationsRaised: '700,000',
                    services: [
                        DocumentServiceEnum.basicLastWillAndTestament,
                        DocumentServiceEnum.advancedLastWillAndTestament,
                        DocumentServiceEnum.deedOfTemporaryGuardianship,
                        DocumentServiceEnum.premiumLastWillAndTestament,
                    ],
                };
            case LocaleEnum.enUS:
                emoji = '🇺🇸';
                return {
                    isDefault: false,
                    localeCode: LocaleEnum.enUS,
                    fullLanguageName: FullLanguageEnum.en,
                    fullLocationName: FullLocationEnum.unitedStates,
                    shorthandLanguageName: ShorthandLanguageEnum.en,
                    translationCode: LanguageEnum.en,
                    locationCode: LocationEnum.us,
                    fullDisplayLocationName: FullDisplayLocationEnum.us,
                    alternateName: 'the United States',
                    currency: 'USD',
                    currencySymbol: '$',
                    emoji,
                    buttonText: `${emoji} | ${ShorthandLanguageEnum.en}`,
                    isEnglish: true,
                    isoCountryLang: ISOCountryLangEnum.en,
                    pricing: {
                        [DocumentServiceEnum.basicLastWillAndTestament]: 149,
                        basicCouple: 223.5,
                        [DocumentServiceEnum.advancedLastWillAndTestament]: 298,
                        advancedCouple: 447,
                        [MembershipEnum.editingMembership]: 39,
                        [DocumentServiceEnum.premiumLastWillAndTestament]: 1020,
                        premiumWillCouple: 1290,
                        premiumEditingMembership: 125,
                        [DocumentServiceEnum.countrySpecificWill]: 298,
                    },
                    updatePriceComparison: '300 - 1000+',
                    hours: '9:30am EST to 6:00pm EST',
                    dateFormat: 'MMM Do, YYYY',
                    donationsRaised: '153,000',
                    services: [
                        DocumentServiceEnum.basicLastWillAndTestament,
                        // DocumentServiceEnum.advancedLastWillAndTestament,
                        // DocumentServiceEnum.deedOfTemporaryGuardianship,
                        // DocumentServiceEnum.enduringPowerOfAttorney,
                        DocumentServiceEnum.premiumLastWillAndTestament,
                    ],
                };
            case LocaleEnum.enHK:
            default:
                emoji = '🇭🇰';
                return {
                    isDefault: true,
                    localeCode: LocaleEnum.enHK,
                    fullLanguageName: FullLanguageEnum.en,
                    fullLocationName: FullLocationEnum.hongKong,
                    shorthandLanguageName: ShorthandLanguageEnum.en,
                    translationCode: LanguageEnum.en,
                    locationCode: LocationEnum.hk,
                    fullDisplayLocationName: 'Hong Kong',
                    alternateName: 'Hong Kong',
                    currency: 'HKD',
                    currencySymbol: 'HK$',
                    emoji,
                    buttonText: `${emoji} | ${ShorthandLanguageEnum.en}`,
                    isEnglish: true,
                    isoCountryLang: ISOCountryLangEnum.en,
                    pricing: {
                        [DocumentServiceEnum.basicLastWillAndTestament]: 1500,
                        basicCouple: 2250,
                        [DocumentServiceEnum.advancedLastWillAndTestament]: 3000,
                        advancedCouple: 4500,
                        [MembershipEnum.editingMembership]: 300,
                        [DocumentServiceEnum.deedOfTemporaryGuardianship]: 1200,
                        [DocumentServiceEnum.enduringPowerOfAttorney]: 7500,
                        [AddonServiceEnum.witness]: 1000,
                        [DocumentServiceEnum.premiumLastWillAndTestament]: 8000,
                        premiumWillCouple: 10000,
                        premiumEditingMembership: 950,
                        [DocumentServiceEnum.countrySpecificWill]: 3000,
                    },
                    updatePriceComparison: '1,000 - 4,000+',
                    hours: '9:30am HKT to 6:00pm HKT',
                    dateFormat: 'Do MMM, YYYY',
                    donationsRaised: '1.2 Million',
                    services: [
                        DocumentServiceEnum.basicLastWillAndTestament,
                        DocumentServiceEnum.advancedLastWillAndTestament,
                        DocumentServiceEnum.deedOfTemporaryGuardianship,
                        DocumentServiceEnum.enduringPowerOfAttorney,
                        DocumentServiceEnum.premiumLastWillAndTestament,
                    ],
                };
        }
    }

    public isHongKong(locale: string): boolean {
        const details = this.getLocaleDetails(locale);
        return details.locationCode === LocationEnum.hk;
    }

    public isSingapore(locale: string): boolean {
        const details = this.getLocaleDetails(locale);
        return details.locationCode === LocationEnum.sg;
    }

    public localizeLink(path: string, lang?: string): string {
        const localeDetails = this.getLocaleDetails(lang || '');
        return localeDetails.isDefault ? path : `/${lang}${path}`;
    }

    public currencySymbol(currency: string): string {
        switch (currency.toLowerCase()) {
            case 'hkd':
                return 'HK$';
            case 'sgd':
                return 'S$';
            case 'myr':
                return 'RM';
            case 'usd':
            default:
                return '$';
        }
    }

    public locationToCurrencySymbol(location: FullLocationEnum): string {
        switch (location) {
            case FullLocationEnum.hongKong:
                return 'HK$';
            case FullLocationEnum.singapore:
                return 'S$';
            case FullLocationEnum.malaysia:
                return 'RM';
            case FullLocationEnum.unitedStates:
            default:
                return '$';
        }
    }
}

export default new LocalizationService();
