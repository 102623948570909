export default {
    onlineWills: {
        titleHK: 'Online Will Service Hong Kong | Online Wills',
        titleSG: 'Online Will Service Singapore | Online Wills',
        titleMY: 'Online Will Service Malaysia | Online Wills',
        titleUS: 'American Online Will Service | Online Wills',
        descriptionHK:
            'Online Will service from HK$1,500 for a single Will and HK$2,250 for couple Wills with unlimited updates. Receive a professional Will in just 2 working days.',
        descriptionSG: 'Online Will service from S$175 for a single Will and S$262 for couple Wills with unlimited updates. Receive a professional Will in just 2 working days.',
        descriptionMY: 'Online Will service from RM800 for a single Will and RM1,200 for couple Wills with unlimited updates. Receive a professional Will in just 2 working days.',
        descriptionUS: 'Online Will service from $149 for a single Will and $223.50 for couple Wills with unlimited updates. Receive a professional Will in just 2 working days.',
        keywordsHK: 'Hong Kong online Will template, Online Will Pricing, online Wills Hong Kong, HK Will, HK Will template',
        keywordsSG: 'Singapore Will template, online Will Singapore, Online Will Pricing, expert Wills online, Will process, SG Will template',
        keywordsMY: 'Malaysia Will template, online Will, Online Will Pricing, expert Wills online, Will process, MY Will template',
        keywordsUS: 'Will template, online Will, Online Will Pricing, expert Wills online, Will process, American Will template',
        s: "'s",
        online: 'online',
        will: 'Will',
        writing: 'writing',
        service: 'service.',
        estatePlanning: 'Online estate planning from ',
        estatePlanning2: ' in just 15 minutes, with free guidance when you need it.',
        How: 'How',
        doesThe: ' does the',
        processWork: ' process work?',
        eachStep:
            'Each step is carefully thought out to make your Will writing experience as smooth as possible. We also include helpful tips and links to relevant guides throughout. Did we mention that our Will experts will personally help you via our chat button?',
        step2: 'Answer some questions',
        step2Body: 'In as little as 15 minutes, provide some information about yourself and choose what you want to go into your Will.',
        step3: 'Review and sign',
        step3Body: 'Receive your completed Will within two working days and follow the simple instructions to sign and validate everything.',
        step4: 'Stay up to date',
        step4Body: 'Your estate planning details will be securely stored in your account. Log back on anytime to make amendments and get an updated Will.',

        ourWills: 'Our Wills',
        ourWillsBody:
            "There is actually more to estate planning than just Wills. That's why our service includes a Will, plus other important documents that help organize your affairs.",
        whyUse: 'Why use our online service?',
        whyUseBody: 'To put it simply, our online Will service saves you time, money, and headaches.',
        whyUseItem: 'Our Wills are comprehensive, competitively affordable, and lasting.',
        whyUseItem2: 'Our platform is easy-to-use, informative, well-guided, and mindfully designed.',
        whyUseItem3: 'Our process is fast and environmentally friendly.',
        cover: 'What does the online Will service cover?',
        coverBody:
            'In our online Will, you can choose executors/ trustees to manage your estate, guardians for your children and pets, and to make distribution of gifts and money to family and friends and divide the rest of your estate to others. You can also have the option to exclude someone from your estate and choose your funeral arrangements.',
        coverBody2:
            'You can also choose to provide details to create a letter of wishes, list of assets and list of digital assets. These documents are separate from your Will, but are useful for your executors to deal with your estate.',
        howLong: 'How long does the entire process take?',
        howLongBody: 'For you, as little as 15 minutes. In total, up to 2 working days, maximum.',
        howLongBody2:
            'The first stage of providing us your information can take as little as 15 minutes. If need more time, however, to consider who to include in your will, our system will save your progress so you can login at a later time to finish the process.',
        howLongBody3: 'Once you submit your information, it will take us up to 2 working days maximum to review, draft, and finalize your Will and other accompanying documents. ',
        howLongBody4: 'The last step is to simply download the final documents, and follow the instructions to sign and store your Will.',
        whatLang: 'In what language are the Will documents written?',
        whatLangBodyHK: 'The Will and accompanying documents are written in the language that you choose for your account. ',
        whatLangBodySG: 'The Will and accompanying documents are written in English. ',
        preview: 'Online platform preview',
        previewBody: 'We have put together a brief video showcasing how our platform looks and what you can expect out of the process.',
        notSuitable: 'Do you fit these restrictions? Consider checking out our premium service, which offers a fully customized experience.',
        premiumService: 'Premium Service',
    },
};
