import { useMemo } from 'react';
import { ExternalToken } from '../models/external-token/external-token';
import { useCustomLocation } from './use-custom-location';

type UseExternalTokenReturn = {
    externalToken: ExternalToken | undefined;
};

const EXTERNAL_TOKEN = 'externalToken';

/**
 * Gets the external token from the URL or from the local storage
 */
export function useExternalToken(): UseExternalTokenReturn {
    const { search, isReady } = useCustomLocation();

    return useMemo<UseExternalTokenReturn>(() => {
        if (isReady) {
            if (search) {
                let token = new URLSearchParams(search).get(EXTERNAL_TOKEN);
                if (token) {
                    localStorage.setItem(EXTERNAL_TOKEN, token);
                } else {
                    token = localStorage.getItem(EXTERNAL_TOKEN);
                }
                return { externalToken: ExternalToken.fromString(token) };
            } else {
                const token = localStorage.getItem(EXTERNAL_TOKEN);
                return { externalToken: ExternalToken.fromString(token) };
            }
        } else {
            return { externalToken: undefined };
        }
    }, [isReady, search]);
}
