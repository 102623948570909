import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { GtagService } from '../services/gtag/gtag.service';
import { GTagEvent } from '../services/gtag/types';
import React from 'react';

const TEST_GROUP = 'test-group';

export enum TestGroup {
    A = 'A',
    B = 'B',
}

type ABTestingProps = {
    group: TestGroup | null;
    isGroupA: boolean;
    isGroupB: boolean;
    onSuccess: (event: GTagEvent) => void;
};

export const ABTestingContext = createContext<ABTestingProps>({} as ABTestingProps);

interface ABTestingProviderProps {
    children: ReactNode;
}

const ABTestingProvider: React.FC<ABTestingProviderProps> = ({ children }) => {
    const [group, setGroup] = useState<TestGroup | null>(null);

    useEffect(() => {
        const group = localStorage.getItem(TEST_GROUP);
        if (Object.values(TestGroup).includes(group as TestGroup)) {
            setGroup(group as TestGroup);
        } else {
            const newGroup = Math.random() < 0.5 ? TestGroup.A : TestGroup.B;
            localStorage.setItem(TEST_GROUP, newGroup);
            setGroup(newGroup);
        }
    }, []);

    const memoizedValues = useMemo<ABTestingProps>(
        () => ({
            group,
            isGroupA: group === TestGroup.A,
            isGroupB: group === TestGroup.B,
            onSuccess: (event: GTagEvent) => {
                if (group) {
                    GtagService.event(`${event} - Group ${group}`);
                }
            },
        }),
        [group]
    );

    return <ABTestingContext.Provider value={memoizedValues}>{children}</ABTestingContext.Provider>;
};

export default ABTestingProvider;

export function useABTesting(): ABTestingProps {
    const context = useContext(ABTestingContext);
    return context;
}
