import localization, { AllChargeableServices, FullLocationEnum } from '../../services/localization/localization.service';
import { Email } from '../../types/models';
import TextUtils from '../../utils/text.utils';
import { DiscountType, IDiscount } from './dto/discount.dto';

export class Discount {
    private _props: IDiscount;

    constructor(props: IDiscount) {
        this._props = props;
    }

    get id(): string {
        return this._props._id;
    }

    get isActive(): boolean {
        return this._props.isActive;
    }

    get isPrivate(): boolean {
        return this._props.isPrivate;
    }

    get name(): string {
        return this._props.name;
    }

    get description(): string {
        return this._props.description;
    }

    get chineseName(): string | undefined {
        return this._props.chineseName;
    }

    get chineseDescription(): string | undefined {
        return this._props.chineseDescription;
    }

    get hasLink(): boolean {
        return !!this._props.link;
    }

    get linkPath(): string | undefined {
        const url = new URL(this._props.link || '');
        return `${url.pathname}${url.hash}`;
    }

    get discountType(): DiscountType {
        return this._props.discountType;
    }

    get isServiceSpecific(): boolean {
        return this._props.services.length > 0;
    }

    get isGeneralDiscount(): boolean {
        return !this.isServiceSpecific;
    }

    get isPercentage(): boolean {
        return this._props.discountType === DiscountType.percentage;
    }

    get isFixed(): boolean {
        return this._props.discountType === DiscountType.fixed;
    }

    get discount(): number {
        return this._props.discount;
    }

    get discountString(): string {
        return TextUtils.addCommas(this.discount, 0);
    }

    /**
     * Formatted discount string for admin panel
     */
    get formattedDiscount(): string {
        if (this.isPercentage) {
            return `${this.discountString}%`;
        } else {
            return `${localization.locationToCurrencySymbol(this.locations[0])}${this.discountString}`;
        }
    }

    get startDate(): Date {
        return new Date(this._props.startDate);
    }

    get endDate(): Date {
        return new Date(this._props.endDate);
    }

    get isLive(): boolean {
        return this.isActive && this.startDate <= new Date() && this.endDate >= new Date();
    }

    get isExpired(): boolean {
        return this.isActive && this.endDate < new Date();
    }

    get isNotYetStarted(): boolean {
        return this.isActive && this.startDate > new Date();
    }

    get emails(): Email[] {
        return this._props.emails;
    }

    get services(): AllChargeableServices[] {
        return this._props.services;
    }

    get locations(): FullLocationEnum[] {
        return this._props.locations;
    }

    isSpecificToService(service: AllChargeableServices): boolean {
        return this._props.services.includes(service);
    }
}
