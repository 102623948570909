export default {
    charities: {
        titleHK: 'Charities We Support | Charities',
        titleSG: 'Charities We Support | Charities',
        titleMY: 'Charities We Support | Charities',
        titleUS: 'Charities We Support | Charities',
        descriptionHK: 'We actively seek to support and highlight Hong Kong charities by raising awareness and by encouraging our clients to give legacy donations.',
        descriptionSG: 'We actively seek to support and highlight Singaporean charities by raising awareness and by encouraging our clients to give legacy donations.',
        descriptionMY: 'We actively seek to support and highlight Malaysian charities by raising awareness and by encouraging our clients to give legacy donations.',
        descriptionUS: 'We actively seek to support and highlight American charities by raising awareness and by encouraging our clients to give legacy donations.',
        keywordsHK: 'Hong Kong charities, legacy donations, NobleWills partners',
        keywordsSG: 'Singapore charities, legacy donations, NobleWills partners',
        keywordsMY: 'Malaysian charities, legacy donations, NobleWills partners',
        keywordsUS: 'American charities, legacy donations, NobleWills partners',
        charities: 'charities',
        weSupport: 'We Support',
        charityBody:
            'With NobleWills, you can easily leave a charitable legacy behind by donating to one of the charities we support or to one of your choice. Our online Will platform allows you to give cash gifts or portions of your estate to charities with just a couple clicks.',
        areYou: 'Are you a charity?',
        areYouBody:
            'Is your charity trying to gain access to legacy donations? By partnering with us, you can highlight your charity as a preset option when our clients are considering gifts and estate distributions.',
        donateToday: 'Donate Today',
        donationFigures: 'Over {{currency}}{{million}} in donations committed so far',
    },
};
