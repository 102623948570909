import { DocSectionsEnum } from '../../../../components/services/wills/document-display';
import { AgreementType } from '../../../../models/agreement/dto/agreement.dto';

export default {
    common: {
        period: '。',
        comma: '，',
        'Hong Kong': '香港',
        Singapore: '新加坡',
        'Start Now': '開始',
        start: '開始',
        'Write my Will': '訂立我的平安紙',
        Login: '登錄',
        'Talk to an expert': '與專業人員聯絡',
        Home: '主頁',
        Wills: '平安紙',
        'Online Wills': '網上平安紙服務',
        // "Premium Wills": "Premium Wills",
        Pricing: '價目',
        Services: '服務',
        deedOfTemporaryGuardianship: '臨時監護權契約',
        temporaryGuardianship: '臨時監護人',
        enduringPowerOfAttorney: '持久授權書',
        'About Us': '關於我們',
        Blog: '網誌',
        'Free Guide': '參考文章',
        'Charities we support': '受惠慈善機構',
        Help: '幫助',
        Contact: '聯絡我們',
        'Contact Us': '聯絡我們',
        'Location Selection': '地區選擇',
        Free: '免費',
        'More Details': '更多詳細資料',
        Product: '產品',
        Price: '價錢',
        'Single Will': '單一份平安紙',
        'Couple Wills': '夫妻各一份平安紙',
        'Editing Membership': '會籍',
        'Plan your future': '您的摯愛 ',
        'with us': '守護',
        Cookies: 'Cookies',
        usesCookies: 'NobleWills 使用cookie來提升我們的網站。請參閱我們有關的『詳細資料』。 ',
        forDetails: ' for details.',
        'Wills made easy.': '輕鬆訂立平安紙.',
        rightsReserved: '© Noble Wills Limited 賢囑有限公司 2021 - {{currentYear}} 版權所有',
        isAWritingService:
            'NobleWills 是一間訂立平安紙的公司。 儘管 NobleWills 是由一組律師構成， NobleWills 不是一間律師事務所、稅務顧問，也不能替代律師對複雜遺產計劃問題的建議。',
        'Read more': '閱讀更多',
        Required: '必需的',
        invalidEmail: '無效的郵件地址',
        cancel: '取消',
        submit: '提交',
        messageSent: '信息已發送!',
        getBack: '我們會盡快回覆您.',
        '404': '404: 未找到',
        '404Description': '此頁面不存在.',
        '404Body': '糟糕了! 您點擊了一個不存在的頁面',
        takeMeHome: '請帶我回家',
        Version: '版本',
        lastEdit: '日期',
        HKD: '港元',
        SGD: '新加坡元',
        ohNo: '糟糕了！',
        errorMessage: '我們遇到了問題。 請稍後再試。',
        Newsletter: '聯絡我們',
        newsletterBody: '提供您的姓名、電郵和居住國家以獲得我們的時事資訊！',
        Frequently: '常見',
        asked: '問題',
        FAQ: '常見問題',
        faqLong: '常見問題',
        searchFaqsAndArticles: '搜索常見問題和文章',
        'Get in touch': '與我們聯絡',
        'Help center': '常見問題中心',
        Contents: '內容',
        free: '免費',
        [DocSectionsEnum.introduction]: '簡介',
        introductionBody: '封面將會列明遺產計劃所包含的文件。',
        [DocSectionsEnum.instructions]: '指引',
        instructionsBody: '指引包括有關令平安紙有法律效應的資料以及隨後完成遺產計劃的步驟，例如簽署隨附文件以及有關您平安紙的存放。',
        [DocSectionsEnum.letterToTheExecutors]: '致執行人/受託人的信函',
        executorsBody: '您的執行人需要知道他們被委任，以方便日後的安排。本信函包括有關他們的責任和執行的流程。',
        [DocSectionsEnum.lastWillAndTestament]: '平安紙',
        willBody: '平安紙上會清楚說明誰是管理您遺產的執行人，孩子和寵物的監護人，以及您的遺產分配等等。',
        [DocSectionsEnum.letterOfWishes]: '願望信',
        wishesBody: '在您訂立平安紙過程中列出的任何願望都會製成一封願望信。',
        [DocSectionsEnum.listOfAssets]: '資產清單',
        assetsBody: '本清單有助減少您平安紙執行人在處理您遺產時所消耗的時間和資源。',
        [DocSectionsEnum.listOfDigitalAssets]: '數碼資產清單',
        digitalAssetsBody: '本清單可以協助您的平安紙執行人管理您的網上資產。',
        seeReviews: '閱讀評論',
        previous: '返回',
        next: '下一步',
        Lawfirms: '律師事務所',
        TraditionalWillWriter: '傳統平安紙公司',
        OnlineTemplate: '網上樣板',
        Process: '過程',
        TimeToComplete: '完成時間',
        Accuracy: '準確性',
        Updates: '更新',
        noblePrice: '網上平安紙服務價目 {{currency}}{{basic}} 或 {{currency}}{{advanced}}.',
        noblePrice2: '綜合平安紙服務價目 {{currency}}{{premium}}+',
        noblePrice3: '夫妻一起購買時會有大折扣。',
        lawFirmPrice: '按小時收費或按您的資產價值收費。',
        lawFirmPrice2: '通常包含隱藏費用以選取更多選項。',
        willWriterPrice: '{{currency}}{{premium}}+',
        templatePrice: '免費',
        nobleProcess: '網上平安紙服務: 創建賬戶、填寫詳細資料及付款，僅需15分鐘。',
        nobleProcess2: '綜合平安紙服務: 以視像模式收集資料。在約定的地點簽字和見證。平安紙由我們的遺囑專家獨自起草。可通過我們的網上平台，進行相關的文件更新。',
        lawFirmProcess: '與律師進行多次面對面諮詢和會面。',
        lawFirmProcess2: '多次來回的電子郵件以完成文件。',
        lawFirmProcess3: '沒有靈活性，只能在律師事務所完成平安紙。',
        willWriterProcess: '與專家進行諮詢以了解您的背景。',
        willWriterProcess2: '面對面的會議和多次來回的電子郵件以完成平安紙。',
        willWriterProcess3: '沒有靈活性，只能在辦公室完成平安紙。',
        templateProcess: '進行大量研究以了解流程、法律、內容和必要條款以製作你的平安紙，但內容準確性未能確定。花大量時間尋找合適的樣板填寫，以希望能夠自己寫到理想的文件。 ',
        nobleTime: '網上平安紙服務: 只需2個工作天。',
        nobleTime2: '綜合平安紙服務: 只需7個工作天。',
        lawFirmTime: '數週',
        willWriterTime: '數週',
        templateTime: '可能需要數週的時間研究用詞、找適合的樣板和填寫準確的資料。',
        nobleAccu: '我們的遺囑專家具有法律背景，並且是全球遺產規劃協會的成員。',
        nobleAccu2: '我們還專注於跨境遺產規劃，可以為眾多司法管轄區撰寫平安紙。',
        lawFirmAccu: '眾多律師事務所不擅於撰寫平安紙，及缺乏對跨境遺產規劃的專業認知。',
        lawFirmAccu2: '它們也被限制規劃跨境遺產，不能撰寫海外平安紙。',
        willWriterAccu: '大部分平安紙撰寫公司沒有法律背景。',
        templateAccu: '未能保證您的研究及用詞是否適當及能使平安紙具有法律約束力。',
        nobleUpdates: '網上平安紙服務: 以每年${{editing}}的價格成為會籍，所有文件都可以無限次更改。',
        nobleUpdates2: '綜合平安紙服務: 價格由每年${{editing}}起。',
        lawFirmUpdates: '親自返回律師事務所，再次支付平安紙原價。',
        willWriterUpdates: '親自返回平安紙撰寫公司，支付平安紙的原價或享有小幅折扣。',
        templateUpdates: '自行研究正確的法律措辭和條款。',
        comparison: '比較 NobleWills 與其他公司。',
        compare: '比較',
        questionnaire: '不知道那個平安紙服務最適合您？ 請打開我們的問卷！',
        questionnaireAction: '回答問卷',
        personalReviewHeading: 'A Human Touch',
        personalReview: '全人手服務，所有文件均由律師背景的專家審核，並非單靠人工智能。',
        countrySpecificWills: '其他國家平安紙',
        countrySpecificWills2: '其他國家平安紙',
        whatDoINeed: '我需要什麼？',
        myAccount: '我的賬戶',
        includesSigning: '時限優惠: 包括在灣仔辦公室簽署和見證至11月底！',
        advancedSigning: '選擇指定服務 - 包括在灣仔辦公室簽署和見證！',
        valuedAt: '總值{{price}}',
        witnessTiming: '星期一至五下午2時至6時',
        daysRemaining: '{{days}}日',
        expired: '已過期',
        specialDeal: '11月份時限優惠！',
        [AgreementType.TermsOfService]: '條款及細則',
        [AgreementType.PrivacyPolicy]: '私隱政策',
        failedToLoad: '載入失敗',
        tryAgain: '請再試一次',
        tryHelpCenter: '有問題嗎？ 請參閱我們的常見問題中心。',
        clear: '清除',
        lookingForHelp: '需要幫忙嗎？',
        helpBody: '請前往「協助與支援」以查看常見問題，或瀏覽「參考文章」以助你完成遺產規劃流程。仍然需要協助？透過「聯絡我們」留下訊息，我們將盡快回覆。',
        inPartnership: '我們與以下機構合作',
    },
};
