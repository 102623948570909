import { AllChargeableServices } from '../../../services/localization/localization.service';
import { IDiscount } from '../../discount/dto/discount.dto';

export enum PartnerCode {
    AIA = 'AIA',
    BOWTIE = 'BOWTIE',
}

export type PriceDiscountData = {
    originalPrice: number;
    discountedPrice: number | undefined;
    discount: IDiscount | undefined;
    partner?: PartnerCode;
};

export type AllChargeableServicesPricesDiscounts = Record<AllChargeableServices, PriceDiscountData | undefined>;

export type PricingDto = {
    services: Partial<AllChargeableServicesPricesDiscounts>;
    currency: string;
    generalDiscount: IDiscount | undefined;
};
